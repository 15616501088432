import React, { FC, useState } from "react";

import dayjs from "dayjs";

import { useGetSubscriptions } from "@api/payment/getSubscriptions";

import { Modal } from "@ui/components";
import {
  Box,
  Button,
  Icon,
  Notification,
  Tag,
  Text,
  Title,
} from "@ui/elements";
import { Cell, Grid, Stack } from "@ui/layout";

import BillingCycleToggle from "../BillingCycleToggle/BillingCycleToggle";
import { ContactChatBox } from "../ContactChatBox/ContactChatBox";

import "./SubscriptionModal.scss";

const SubscriptionTableSkeleton = () => {
  return (
    <Grid>
      <Cell>
        <Box>
          <Stack direction="column" gap="xl">
            <Stack justify="between">
              <Text variant="secondary" isLoading>
                Weekly
              </Text>
            </Stack>
            <Stack align="end" gap="xs">
              <Title isLoading>4.5E</Title>
              <Text variant="secondary" isLoading>
                / week
              </Text>
            </Stack>
            <Button isLoading color="primary">
              Choose Plan
            </Button>
          </Stack>
        </Box>
      </Cell>
      <Cell>
        <Box>
          <Stack direction="column" gap="xl">
            <Stack justify="between">
              <Text variant="secondary" isLoading>
                Weekly
              </Text>
            </Stack>
            <Stack align="end" gap="xs">
              <Title isLoading>4.5E</Title>
              <Text variant="secondary" isLoading>
                / week
              </Text>
            </Stack>
            <Button isLoading color="primary">
              Choose Plan
            </Button>
          </Stack>
        </Box>
      </Cell>
    </Grid>
  );
};

type SubscriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SubscriptionModal: FC<SubscriptionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    data: subscriptionData,
    error: subscriptionError,
    isLoading: subscriptionLoading,
  } = useGetSubscriptions();

  const [selectedInterval, setSelectedInterval] = useState<"week" | "month">(
    "month",
  );

  const intervalSubscription = subscriptionData?.subscriptions?.find(
    (subscription) => subscription.interval === selectedInterval,
  );
  const activeSubscription = subscriptionData?.subscriptions?.find(
    (subscription) => subscription.active_subscription,
  );

  return (
    <Modal
      isActive={isOpen}
      onClose={onClose}
      header={
        <Stack align="center">
          <Icon icon="Sparkle" />
          <Title size={4}>Subscription</Title>
        </Stack>
      }
    >
      <Stack direction="column" gap="xxl">
        <Stack direction="column">
          {subscriptionError && (
            <Notification color="danger">
              <Text weight="bold">
                {subscriptionError.response?.data.error}
              </Text>
              <Text>{subscriptionError.response?.data.message}</Text>
            </Notification>
          )}
          {subscriptionLoading && <SubscriptionTableSkeleton />}
          {!activeSubscription && (
            <BillingCycleToggle
              value={selectedInterval}
              onChange={setSelectedInterval}
            />
          )}
          {subscriptionData && (
            <Grid minColWidth={11}>
              <Cell>
                <Box className="subscription-box">
                  <Stack
                    direction="column"
                    gap="xl"
                    className="subscription-box__inner"
                  >
                    <Stack direction="column" gap="xl">
                      <Stack direction="column" gap="sm">
                        <Stack justify="between">
                          <Title size={5}>Free</Title>
                          <Stack>
                            {!activeSubscription && (
                              <Icon
                                icon="CheckCircle"
                                weight="fill"
                                className="has-text-success"
                              />
                            )}
                          </Stack>
                        </Stack>
                        <Stack align="end" gap="xs">
                          <Title size={2}>€0</Title>
                          <Text variant="secondary"></Text>
                        </Stack>
                      </Stack>
                      <Stack direction="column">
                        <Text
                          hasIcon
                          variant="secondary"
                          className="subscription-feature-item"
                        >
                          <Icon icon="CheckCircle" />
                          <span>Up to 3 matches/day</span>
                        </Text>
                        <Text
                          hasIcon
                          variant="secondary"
                          className="subscription-feature-item"
                        >
                          <Icon icon="CheckCircle" />
                          <span>In-depth Match Analysis</span>
                        </Text>
                        <Text
                          hasIcon
                          variant="secondary"
                          className="subscription-feature-item"
                        >
                          <Icon icon="CheckCircle" />
                          <span>No Commitment</span>
                        </Text>
                      </Stack>
                    </Stack>
                    {!activeSubscription && (
                      <Button disabled>
                        <span>Active Plan</span>
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Cell>
              <Cell>
                <Box className="subscription-box">
                  <Stack
                    direction="column"
                    gap="xl"
                    className="subscription-box__inner"
                  >
                    <Stack direction="column" gap="xl">
                      <Stack direction="column" gap="sm">
                        <Stack justify="between" gap="sm">
                          <Title size={5}>Standard</Title>
                          <Stack>
                            {activeSubscription?.cancels_at_date && (
                              <Tag>
                                <Icon icon="Alarm" size="small" />
                                <span>
                                  Cancels at{" "}
                                  {dayjs(
                                    activeSubscription?.cancels_at_date,
                                  ).format("MMM DD")}
                                </span>
                              </Tag>
                            )}
                            {activeSubscription &&
                              !activeSubscription.cancels_at_date && (
                                <Icon
                                  icon="CheckCircle"
                                  weight="fill"
                                  className="has-text-success"
                                />
                              )}
                          </Stack>
                        </Stack>
                        <Stack align="end" gap="xs">
                          <Title>€{intervalSubscription?.price}</Title>
                          <Text variant="secondary">
                            / {intervalSubscription?.interval}
                          </Text>
                        </Stack>
                      </Stack>
                      <Stack direction="column">
                        <Text
                          hasIcon
                          variant="secondary"
                          className="subscription-feature-item"
                        >
                          <Icon icon="CheckCircle" />
                          <span>Unlimited tips</span>
                        </Text>
                        <Text
                          hasIcon
                          variant="secondary"
                          className="subscription-feature-item"
                        >
                          <Icon icon="CheckCircle" />
                          <span>24/7 support</span>
                        </Text>
                        <Text
                          hasIcon
                          variant="secondary"
                          className="subscription-feature-item"
                        >
                          <Icon icon="CheckCircle" />
                          <span>Bankroll Management</span>
                        </Text>
                        <Text
                          hasIcon
                          variant="secondary"
                          className="subscription-feature-item"
                        >
                          <Icon icon="CheckCircle" />
                          <span>Custom Betting Strategies</span>
                        </Text>
                        <Text
                          hasIcon
                          variant="secondary"
                          className="subscription-feature-item"
                        >
                          <Icon icon="CheckCircle" />
                          <span>Personalisation</span>
                        </Text>
                      </Stack>
                    </Stack>
                    {activeSubscription ? (
                      activeSubscription.cancels_at_date ? (
                        <Button
                          as="link"
                          href={subscriptionData?.renew_subscription_link}
                        >
                          Renew Plan
                        </Button>
                      ) : (
                        <Button disabled>Active Plan</Button>
                      )
                    ) : (
                      <Button
                        color="primary"
                        as="link"
                        href={
                          activeSubscription
                            ? subscriptionData?.update_subscription_link
                            : intervalSubscription?.payment_link
                        }
                        onClick={() => {
                          localStorage.setItem(
                            "selectedSubscriptionPrice",
                            String(intervalSubscription?.price),
                          );
                        }}
                      >
                        {subscriptionData?.has_active_subscription
                          ? "Update Plan"
                          : "Choose Plan"}
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Cell>
            </Grid>
          )}
          {subscriptionData?.manage_subscription_link && (
            <Stack>
              <Button
                variant="text"
                as="link"
                href={subscriptionData?.manage_subscription_link}
              >
                Manage your subscription
              </Button>
            </Stack>
          )}
        </Stack>
        <ContactChatBox />
      </Stack>
    </Modal>
  );
};
