import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import "./PageTemplate.scss";
import classNames from "classnames";
import dayjs from "dayjs";
import { NavLink, Outlet, Link as RouterLink } from "react-router-dom";

import {
  useGetUserPreferences,
  useUpdateTelegramPreferences,
} from "@apiv2/o1-typescript-service";

import { Currency } from "@api/user/client";

import {
  Dropdown,
  DropdownContent,
  DropdownDivider,
  DropdownItem,
} from "@ui/components";
import { Text, Icon, Icons, Button, Delete } from "@ui/elements";
import { CurrencyInput } from "@ui/forms";
import { Stack } from "@ui/layout";

import {
  useAuth,
  useModal,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components";

import { FreePlanBalanceIndicator } from "../FreePlanBalanceIndicator/FreePlanBalanceIndicator";
import { FullPageLoading } from "../FullPageLoading/FullPageLoading";
import { ScrollToTop } from "../ScrollToTop/ScrollToTop";
import { TelegramJoinModal } from "../TelegramJoinModal/TelegramJoinModal";
import { UserSourceProvider } from "../UserSourceProvider/UserSourceProvider";

type LinkProps = PropsWithChildren<{
  icon: Icons;
  to: string;
  disabled?: boolean;
}>;

const Link: FC<LinkProps> = ({ icon, to, children, disabled }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames([
          "template-nav__item",
          { "is-active": isActive, disabled: disabled },
        ])
      }
    >
      {({ isActive }) => (
        <>
          <Icon weight={isActive ? "fill" : "regular"} icon={icon} />
          <Text weight={isActive ? "bold" : "normal"}>{children}</Text>
        </>
      )}
    </NavLink>
  );
};

type PageSettingsContextType = {
  bankroll?: number | null;
  setBankroll: (value: undefined | number | null) => void;
  currency?: Currency;
  kellyFraction?: number;
  focusBankroll?: () => void;
  hasSubscription?: boolean;
  isLoading?: boolean;
};

const PageSettingsContext = createContext<PageSettingsContextType | undefined>(
  undefined,
);

export const usePageSettings = () => {
  const context = useContext(PageSettingsContext);
  if (!context) {
    throw new Error(
      "usePageSettings must be used within a PageSettings provider",
    );
  }
  return context;
};

const bankrollStorage = {
  set: (amount?: number | string | null) => {
    const date = dayjs().format("YYYY-MM-DD");

    localStorage.setItem(
      "savedBankroll",
      JSON.stringify({
        [date]: amount,
      }),
    );
  },
  get: () => {
    const date = dayjs().format("YYYY-MM-DD");
    const data = JSON.parse(localStorage.getItem("savedBankroll") || "{}");

    return data[date] || undefined;
  },
};

export const PageTemplate: FC<PropsWithChildren> = () => {
  const [open, setOpen] = useState(true);
  const [telegramModalOpen, setTelegramModalOpen] = useState(false);
  const [bankrollInfoOpen, setBankrollInfoOpen] = useState(false);
  const bankrollInputRef = useRef<HTMLInputElement>(null);
  const [bankroll, setBankroll] = useState<undefined | number | null>(
    bankrollStorage.get(),
  );
  const {
    data: userPreferenceData,
    refetch: refetchUserPreferences,
    isLoading: userPreferencesLoading,
  } = useGetUserPreferences();
  const { mutateAsync: updateTelegramPreferences } =
    useUpdateTelegramPreferences();
  const authContext = useAuth();
  const modals = useModal();

  const signo = useMemo(() => {
    if (
      authContext.userAttributes?.given_name &&
      authContext.userAttributes?.family_name
    ) {
      return (
        authContext.userAttributes.given_name.slice(0, 1) +
        authContext.userAttributes.family_name.slice(0, 1)
      );
    }
  }, [authContext.userAttributes]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!bankrollInputRef.current?.contains(event.target as Node)) {
        setBankrollInfoOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleBankrollFocus = () => {
    bankrollInputRef?.current?.focus();
    setBankrollInfoOpen(true);
  };

  const hasSubscription = !!userPreferenceData?.hasActiveSubscription;

  useEffect(() => {
    if (
      userPreferenceData &&
      !userPreferenceData.dismissedTelegramPermanently &&
      userPreferenceData.telegramStale &&
      !modals.hasOpenedModal
    ) {
      setTelegramModalOpen(true);
    }
  }, [userPreferenceData, modals.hasOpenedModal]);

  return (
    <UserSourceProvider>
      <TelegramJoinModal
        isOpen={telegramModalOpen}
        onClose={async (dismissedPermanently) => {
          void updateTelegramPreferences({
            data: {
              telegramJoined: false,
              dismissedTelegramPermanently: dismissedPermanently,
            },
          });
          void refetchUserPreferences();
          setTelegramModalOpen(false);
        }}
        onJoinTelegram={async (dismissedPermanently) => {
          void updateTelegramPreferences({
            data: {
              telegramJoined: true,
              dismissedTelegramPermanently: dismissedPermanently,
            },
          });
          void refetchUserPreferences();
          setTelegramModalOpen(false);
        }}
      />
      <PageSettingsContext.Provider
        value={{
          bankroll,
          setBankroll,
          currency: userPreferenceData?.currency,
          kellyFraction: Number(userPreferenceData?.kellyFraction),
          focusBankroll: handleBankrollFocus,
          hasSubscription,
          isLoading: userPreferencesLoading,
        }}
      >
        <ScrollToTop />
        <div className="page-template-wrapper">
          <Stack justify="between" className="template-header">
            <Stack gap="md" align="center">
              <RouterLink
                to="/"
                className={classNames([
                  "template-header__logo",
                  { "is-active": open },
                ])}
              >
                <div className="image">
                  <img src="logo.png" />
                </div>
                <p className="logo-text">
                  Football Genie <span>AI</span>
                </p>
              </RouterLink>
              <Button
                className="menu-toggle"
                isRounded
                variant="light"
                onClick={() => setOpen(!open)}
              >
                <Icon size="small" icon={open ? "TextOutdent" : "TextIndent"} />
              </Button>
              <FreePlanBalanceIndicator />
            </Stack>

            <Stack gap="sm" align="center" justify="end">
              <Tooltip open={bankrollInfoOpen} placement="bottom-end">
                <TooltipTrigger>
                  <div className={classNames("control has-icons-left")}>
                    <CurrencyInput
                      className="bankroll-input"
                      variant="grey"
                      ref={bankrollInputRef}
                      placeholder="My bankroll"
                      currency={userPreferenceData?.currency}
                      value={bankroll}
                      onFocus={() => setBankrollInfoOpen(false)}
                      onChange={(event) => {
                        setBankrollInfoOpen(false);
                        setBankroll(Number(event.target.value));
                        bankrollStorage.set(Number(event.target.value));
                      }}
                    />
                    <Icon className="is-left" size="small" icon="Coins" />
                    {!!bankroll && (
                      <Delete
                        size="small"
                        className="bankroll-input-delete"
                        onClick={() => {
                          setBankroll(undefined);
                          bankrollStorage.set(undefined);
                        }}
                      />
                    )}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="tooltip">
                    <Stack direction="column">
                      <Text>You must set your current bankroll first.</Text>
                    </Stack>
                  </div>
                </TooltipContent>
              </Tooltip>
              <Dropdown
                placement="bottom-end"
                trigger={
                  <Button isRounded variant="light" color="primary">
                    <span className="icon">{signo}</span>
                  </Button>
                }
              >
                <DropdownContent>
                  <DropdownItem onClick={() => modals.open("accountSettings")}>
                    <Icon icon="User" size="small" />
                    Account
                  </DropdownItem>
                  <DropdownItem onClick={() => modals.open("subscription")}>
                    <Icon icon="Sparkle" size="small" />
                    Subscription
                  </DropdownItem>
                  <DropdownItem onClick={() => modals.open("preferences")}>
                    <Icon icon="Gear" size="small" />
                    Preferences
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem onClick={() => authContext.logout()}>
                    <Icon icon="SignOut" size="small" />
                    Sign Out
                  </DropdownItem>
                </DropdownContent>
              </Dropdown>
              <Button
                variant="white"
                isRounded
                onClick={() => modals.open("contact")}
              >
                <Icon icon="Info" />
              </Button>
            </Stack>
          </Stack>
          <div className="page-template">
            <Stack
              className={classNames(["template-nav", { "is-active": open }])}
              direction="column"
              gap="sm"
            >
              <Link to="/" icon="Layout">
                Dashboard
              </Link>
              <Link to="/upcoming-tips" icon="MagicWand">
                Upcoming Tips
              </Link>
              <Link to="/ai-performance" icon="ChartBar">
                AI Performance
              </Link>
              {hasSubscription ? (
                <Link to="/my-bets" icon="BookmarkSimple">
                  My Tips
                </Link>
              ) : (
                <Tooltip placement="bottom-start">
                  <TooltipTrigger
                    className={classNames("template-nav__item disabled", {
                      "is-closed": !open,
                    })}
                  >
                    <Icon icon="BookmarkSimple" />
                    <p className="text has-text-weight-normal">My Tips</p>
                    <div className="locked-badge">
                      <Icon icon="LockSimple" />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="tooltip">
                      <Stack direction="column">
                        <Text>
                          Saving tip is only available to subscribed users.
                          Choose a plan to get started.
                        </Text>
                      </Stack>
                    </div>
                  </TooltipContent>
                </Tooltip>
              )}
            </Stack>
            <div
              className={classNames("page-template__content", {
                "has-free-plan": !hasSubscription,
              })}
            >
              <div className="page-template__content__inner">
                {userPreferencesLoading ? <FullPageLoading /> : <Outlet />}
              </div>
            </div>
          </div>
        </div>
      </PageSettingsContext.Provider>
    </UserSourceProvider>
  );
};
