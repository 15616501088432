import React from "react";

import { Text } from "@ui/elements";

type TotalTextProps = {
  total_tips?: number;
  total_matches?: number;
  isLoading?: boolean;
};

export const TotalText: React.FC<TotalTextProps> = ({
  total_tips,
  total_matches,
  isLoading,
}) => {
  if (total_tips === 0 && total_matches === 0) {
    return null;
  }

  const tipsText =
    total_tips === 1 ? (
      <strong>1 tip</strong>
    ) : (
      <strong>{total_tips} tips</strong>
    );
  const matchesText =
    total_matches === 1 ? (
      <strong>1 match</strong>
    ) : (
      <strong>{total_matches} matches</strong>
    );

  return (
    <Text
      variant="secondary"
      isLoading={isLoading}
      style={{ width: "fit-content" }}
    >
      Total of {tipsText} from {matchesText}.
    </Text>
  );
};
