import React, { useMemo } from "react";

import { Link } from "react-router-dom";

import {
  Category as TipCategory,
  useGetTipsSummary,
  useInitialFiltersGet,
} from "@apiv2/o1-typescript-service";

import {
  formatHitRate,
  formatOdds,
  formatRoi,
  getRangeFromTimePeriod,
} from "@utils";

import { useGetSettings } from "@api/settings/getSettings";

import { TitleBlock } from "@ui/components";
import { Box, Button, Icon, IconCard, Text, Title } from "@ui/elements";
import { Cell, Grid, Stack } from "@ui/layout";

import { Filter } from "@components";

import { defaultFormatFilterValue } from "../../../../../components/MultiFilter/Inputs/formatters";
import { TotalText } from "../../../../../components/TotalText/TotalText";
import { reconstructFilters } from "../../../ai-performance/AIPerformance";

export const AIPerformancePanel = () => {
  // Get date ranges for last 7 days and last 30 days
  const [from30, to30] = getRangeFromTimePeriod("last-30-days");
  const [from7, to7] = getRangeFromTimePeriod("last-7-days");

  const { data: settingsData, isLoading: settingsLoading } = useGetSettings();

  // Fetch initial filters for both periods
  const { data: initialFiltersData30, isLoading: initialFiltersLoading30 } =
    useInitialFiltersGet({
      from: from30,
      to: to30,
      type: "resultOriented",
    });

  const { data: initialFiltersData7, isLoading: initialFiltersLoading7 } =
    useInitialFiltersGet({
      from: from7,
      to: to7,
      type: "resultOriented",
    });

  // Fetch summary data for both periods
  const { data: summaryData30, isLoading: summaryLoading30 } =
    useGetTipsSummary(
      {
        from: from30,
        to: to30,
        type: "resultOriented",
        ...initialFiltersData30,
      },
      {
        query: {
          enabled: !!initialFiltersData30,
        },
      },
    );

  const { data: summaryData7, isLoading: summaryLoading7 } = useGetTipsSummary(
    {
      from: from7,
      to: to7,
      type: "resultOriented",
      ...initialFiltersData7,
    },
    {
      query: {
        enabled: !!initialFiltersData7,
      },
    },
  );

  // Determine which period is more profitable once both are loaded
  const { summaryData, initialFiltersData, period } = useMemo(() => {
    if (summaryData7 && summaryData30) {
      return summaryData7.profit_loss > summaryData30.profit_loss
        ? {
            summaryData: summaryData7,
            initialFiltersData: initialFiltersData7,
            period: "7 days",
          }
        : {
            summaryData: summaryData30,
            initialFiltersData: initialFiltersData30,
            period: "30 days",
          };
    }
    // Default to 30 days if we don't have both
    return {
      summaryData: summaryData30,
      initialFiltersData: initialFiltersData30,
      period: "30 days",
    };
  }, [summaryData7, summaryData30, initialFiltersData7, initialFiltersData30]);

  const plColor = useMemo(() => {
    if (summaryData?.profit_loss != null) {
      if (summaryData?.profit_loss > 0) return "success";
      if (summaryData?.profit_loss < 0) return "danger";
    }
    return undefined;
  }, [summaryData?.profit_loss]);

  const optionsMap = useMemo(() => {
    return {
      ratings: [
        { value: "super safe", label: "Super Safe", color: "success" },
        { value: "safe", label: "Safe", color: "success" },
        { value: "value", label: "Value", color: "info" },
        { value: "risky", label: "Risky", color: "danger" },
      ],
      leagues:
        settingsData?.leagues.map((league) => ({
          value: String(league.league.id),
          label: league.league.name,
          image: league.league.logo,
        })) || [],
      categories: Object.values(TipCategory).map((value) => ({
        value,
        label: value,
      })),
      match_status: [
        { value: "not-started", label: "Not Started" },
        { value: "live", label: "Live" },
        { value: "finished", label: "Finished" },
      ],
      tip_status: [
        { value: "won", label: "Won" },
        { value: "lost", label: "Lost" },
        { value: "unsettled", label: "Unsettled" },
        { value: "push", label: "Push" },
      ],
    };
  }, [settingsData]);

  const filters: Filter[] = useMemo(() => {
    if (initialFiltersData) {
      return reconstructFilters(initialFiltersData).filter((e) => e.enabled);
    }
    return [];
  }, [initialFiltersData]);

  const isLoading =
    initialFiltersLoading7 ||
    initialFiltersLoading30 ||
    summaryLoading7 ||
    summaryLoading30 ||
    settingsLoading ||
    !summaryData;

  return (
    <Stack direction="column" gap="md" isFullwidth>
      <TitleBlock
        title="Recommended Strategy"
        subtitle={`Based on last ${period}`}
      />
      <Stack isFullwidth direction="column" gap="xl">
        <Grid minColWidth={7}>
          {filters?.map((e) => {
            const formattedValue = defaultFormatFilterValue(e, optionsMap);

            return (
              <Box key={`dashboard-ai-performance-panel-box-${e.id}`}>
                <Stack align="center">
                  {e.icon && <Icon icon={e.icon} />}
                  <Stack direction="column" gap="none" minWNone>
                    <Text variant="secondary" size="small" truncate>
                      {e.name}
                    </Text>
                    {formattedValue}
                  </Stack>
                </Stack>
              </Box>
            );
          })}
        </Grid>
        <Stack direction="column" gap="xxs">
          <TotalText
            total_tips={summaryData?.total_tips}
            total_matches={summaryData?.total_matches}
          />
          <Grid type="fixed" cols={{ mobile: 2, tablet: 1, desktop: 2 }}>
            <Cell colSpan={{ mobile: 2, tablet: 1, desktop: 2 }}>
              <Box isFullwidth>
                <Stack align="center">
                  <IconCard size="medium" icon="Coins" type={plColor} />
                  <Stack direction="column" gap="xxs">
                    <Text variant="secondary">Profit/Loss</Text>
                    <Title noWrap isLoading={isLoading} color={plColor}>
                      {formatRoi(summaryData?.profit_loss)}
                    </Title>
                  </Stack>
                </Stack>
              </Box>
            </Cell>
            <Cell colSpan={{ mobile: 2, tablet: 1, desktop: 2 }}>
              <Box isFullwidth>
                <Stack align="center">
                  <IconCard icon="Target" />
                  <Stack direction="column" gap="none">
                    <Text size="small" noWrap>
                      Success Rate
                    </Text>
                    <Title noWrap size={5} isLoading={isLoading}>
                      {formatHitRate(summaryData?.hit_rate)}
                    </Title>
                  </Stack>
                </Stack>
              </Box>
            </Cell>
            <Cell>
              <Box isFullwidth>
                <Stack align="center">
                  <IconCard icon="Coin" />
                  <Stack direction="column" gap="none">
                    <Text size="small" noWrap>
                      ROI
                    </Text>
                    <Title size={5} noWrap isLoading={isLoading}>
                      {formatRoi(summaryData?.roi)}
                    </Title>
                  </Stack>
                </Stack>
              </Box>
            </Cell>
            <Cell>
              <Box isFullwidth>
                <Stack align="center">
                  <IconCard icon="Scales" />
                  <Stack direction="column" gap="none">
                    <Text size="small" noWrap>
                      Avg. Odds
                    </Text>
                    <Title size={5} noWrap isLoading={isLoading}>
                      {formatOdds(summaryData?.avg_odds)}
                    </Title>
                  </Stack>
                </Stack>
              </Box>
            </Cell>
          </Grid>
        </Stack>
      </Stack>
      <Link to="/ai-performance">
        <Button>View Details</Button>
      </Link>
    </Stack>
  );
};
