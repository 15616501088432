/**
 * Generated by orval v7.0.0 🍺
 * Do not edit manually.
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { customInstance } from "./custom-instance";
import type { ErrorType } from "./custom-instance";
export type GetFixturesParams = {
  ids: number[];
};

export type InitialFiltersGetParams = {
  from?: string;
  to?: string;
  type: TipType;
};

export type GetTopPerformersParams = {
  from?: string;
  to?: string;
  type: TipType;
};

export type GetTipsSummaryParams = {
  type: TipType;
  safe_to_bet?: boolean;
  min_predictability?: number;
  max_predictability?: number;
  min_match_importance?: number;
  max_match_importance?: number;
  min_odds?: number;
  max_odds?: number;
  season?: number;
  min_probability?: number;
  max_probability?: number;
  min_edge?: number;
  max_edge?: number;
  min_stake?: number;
  max_stake?: number;
  include_ratings?: Ratings[];
  include_categories?: Category[];
  include_leagues?: number[];
  best_choice?: boolean;
  from?: string;
  to?: string;
  sort?: SortOrder;
  kellyFraction?: number;
  initialBankroll?: number;
};

export type GetUpcomingTipsParams = {
  type: TipType;
  safe_to_bet?: boolean;
  min_predictability?: number;
  max_predictability?: number;
  min_match_importance?: number;
  max_match_importance?: number;
  min_odds?: number;
  max_odds?: number;
  season?: number;
  min_probability?: number;
  max_probability?: number;
  min_edge?: number;
  max_edge?: number;
  min_stake?: number;
  max_stake?: number;
  include_ratings?: Ratings[];
  include_categories?: Category[];
  include_leagues?: number[];
  best_choice?: boolean;
  from?: string;
  to?: string;
  sort?: SortOrder;
  page?: number;
  pageSize?: number;
};

export type GetTipsPerformanceParams = {
  type: TipType;
  safe_to_bet?: boolean;
  min_predictability?: number;
  max_predictability?: number;
  min_match_importance?: number;
  max_match_importance?: number;
  min_odds?: number;
  max_odds?: number;
  season?: number;
  min_probability?: number;
  max_probability?: number;
  min_edge?: number;
  max_edge?: number;
  min_stake?: number;
  max_stake?: number;
  include_ratings?: Ratings[];
  include_categories?: Category[];
  include_leagues?: number[];
  best_choice?: boolean;
  from?: string;
  to?: string;
  sort?: SortOrder;
  page?: number;
  pageSize?: number;
};

export type GetTipsParams = {
  safe_to_bet?: boolean;
  min_predictability?: number;
  max_predictability?: number;
  min_match_importance?: number;
  max_match_importance?: number;
  type: TipType;
  sort?: SortOrder;
  to?: string;
  from?: string;
  best_choice?: boolean;
  include_leagues?: number[];
  include_tip_status?: TipStatus[];
  include_categories?: Category[];
  include_ratings?: Ratings[];
  max_stake?: number;
  min_stake?: number;
  max_edge?: number;
  min_edge?: number;
  max_probability?: number;
  min_probability?: number;
  season?: number;
  max_odds?: number;
  min_odds?: number;
  page?: number;
  pageSize?: number;
  include_match_status?: MatchStatus[];
};

export type DeleteUserTip200 = {
  id: string;
};

export type GetUserTipsSummaryParams = {
  safe_to_bet?: boolean;
  min_predictability?: number;
  max_predictability?: number;
  min_match_importance?: number;
  max_match_importance?: number;
  min_odds?: number;
  max_odds?: number;
  season?: number;
  min_probability?: number;
  max_probability?: number;
  min_edge?: number;
  max_edge?: number;
  min_stake?: number;
  max_stake?: number;
  include_ratings?: Ratings[];
  include_categories?: Category[];
  include_tip_status?: TipStatus[];
  include_leagues?: number[];
  best_choice?: boolean;
  from?: string;
  to?: string;
  sort?: SortOrder;
  type?: TipType;
};

export type GetUserTipsParams = {
  safe_to_bet?: boolean;
  min_predictability?: number;
  max_predictability?: number;
  min_match_importance?: number;
  max_match_importance?: number;
  type: TipType;
  sort?: SortOrder;
  to?: string;
  from?: string;
  best_choice?: boolean;
  include_leagues?: number[];
  include_tip_status?: TipStatus[];
  include_categories?: Category[];
  include_ratings?: Ratings[];
  max_stake?: number;
  min_stake?: number;
  max_edge?: number;
  min_edge?: number;
  max_probability?: number;
  min_probability?: number;
  season?: number;
  max_odds?: number;
  min_odds?: number;
  page?: number;
  pageSize?: number;
  include_match_status?: MatchStatus[];
};

export interface ContactBody {
  email: string;
  message: string;
  name: string;
  recaptchaToken: string;
}

export interface ContactResponse {
  email: string;
  message: string;
  name: string;
}

export type FixtureRecordAllOfId = string | number;

export type FixtureRecordAllOf = {
  awayTeamId: number;
  date: string;
  homeTeamId: number;
  id: FixtureRecordAllOfId;
  lastFetchedAt: number;
  leagueId: number;
};

export type FixtureRecord = FixtureItem & FixtureRecordAllOf;

export interface FreeAnalysisBalanceResponse {
  can_unlock: boolean;
  max_free_analysis: number;
  next_unlock_date: string;
  unlocked_analysis: number;
}

export type UnlockFailReason =
  (typeof UnlockFailReason)[keyof typeof UnlockFailReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnlockFailReason = {
  error: "error",
  max_analysis: "max_analysis",
  already_unlocked: "already_unlocked",
  success: "success",
} as const;

export interface UnlockMatchResponse {
  message: string;
  reason: UnlockFailReason;
  success: boolean;
}

export type MatchAnalysisItemSummary = {
  match_importance: number;
  predictability: number;
  safe_for_betting: boolean;
};

export interface MatchAnalysisItem {
  correct_score: string;
  correct_score_reasoning: string;
  id: string;
  match_preview: string;
  recommendations: MatchAnalysisItemRecommendations;
  summary: MatchAnalysisItemSummary;
  team_comparison: MatchAnalysisItemTeamComparison;
}

export type MatchAnalysisItemRecommendationsThingsToAvoidItem = {
  short_reasoning: string;
  title: string;
};

export type MatchAnalysisItemRecommendationsBettingRecommendationsItem = {
  short_reasoning: string;
  title: string;
};

export type MatchAnalysisItemRecommendations = {
  betting_recommendations: MatchAnalysisItemRecommendationsBettingRecommendationsItem[];
  things_to_avoid: MatchAnalysisItemRecommendationsThingsToAvoidItem[];
};

export interface MatchAnalysisTeamStats {
  injury_impact: number;
  motivations: number;
  recent_form: number;
  tactical_advantage: number;
  team_health_index: number;
}

export type MatchAnalysisItemTeamComparison = {
  away_team: MatchAnalysisTeamStats;
  home_team: MatchAnalysisTeamStats;
  reasoning: string;
};

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickStrategyExcludeKeyofStrategyUserIdOrCreatedAtOrUpdatedAt {
  filters: StrategyFilter[];
  id: string;
  name: string;
}

export type StrategyFilterTypes =
  (typeof StrategyFilterTypes)[keyof typeof StrategyFilterTypes];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StrategyFilterTypes = {
  ratings: "ratings",
  leagues: "leagues",
  categories: "categories",
  odds: "odds",
  edge: "edge",
  stake: "stake",
  probability: "probability",
  predictability: "predictability",
  best_choice: "best_choice",
  safe_to_bet: "safe_to_bet",
  match_importance: "match_importance",
} as const;

export type StrategyFilterAllOf = {
  id: StrategyFilterTypes;
};

export type StrategyFilter = StrategyFilterValue & StrategyFilterAllOf;

export interface Strategy {
  created_at: string;
  filters: StrategyFilter[];
  id: string;
  name: string;
  updated_at: string;
  user_id: string;
}

export interface UpdateStrategyBody {
  filters: StrategyFilter[];
  id: string;
  name: string;
}

export interface UpdateStrategyResponse {
  created_at: string;
  filters: StrategyFilter[];
  id: string;
  name: string;
  updated_at: string;
  user_id: string;
}

export interface CreateStrategyBody {
  filters: StrategyFilter[];
  name: string;
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickStrategyExcludeKeyofStrategyIdOrUserIdOrCreatedAtOrUpdatedAt {
  filters: StrategyFilter[];
  name: string;
}

export interface CreateStrategyResponse {
  created_at: string;
  filters: StrategyFilter[];
  id: string;
  name: string;
  updated_at: string;
  user_id: string;
}

export type MultiSelectFilterValueValueItem = string | number;

export type MultiSelectFilterValueType =
  (typeof MultiSelectFilterValueType)[keyof typeof MultiSelectFilterValueType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MultiSelectFilterValueType = {
  "multi-select-colored": "multi-select-colored",
  "multi-select-image": "multi-select-image",
  "multi-select": "multi-select",
} as const;

export interface MultiSelectFilterValue {
  type: MultiSelectFilterValueType;
  value: MultiSelectFilterValueValueItem[];
}

export type StrategyFilterValue =
  | SwitchFilterValue
  | RangeFilterValue
  | MultiSelectFilterValue;

export type RangeFilterValueValue = {
  /** @nullable */
  max: number | null;
  /** @nullable */
  min: number | null;
};

/**
 */
export type RangeFilterValueType =
  (typeof RangeFilterValueType)[keyof typeof RangeFilterValueType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RangeFilterValueType = {
  range: "range",
} as const;

export interface RangeFilterValue {
  /** */
  type: RangeFilterValueType;
  value: RangeFilterValueValue;
}

/**
 */
export type SwitchFilterValueType =
  (typeof SwitchFilterValueType)[keyof typeof SwitchFilterValueType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SwitchFilterValueType = {
  switch: "switch",
} as const;

export interface SwitchFilterValue {
  /** */
  type: SwitchFilterValueType;
  value: boolean;
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt {
  alias: string;
  id: string;
  team_name: string;
}

export type UpdateTeamNameAliasBody =
  PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt;

/**
 * Construct a type with the properties of T except for those in type K.
 */
export type OmitTeamNameAliasCreatedAtOrUpdatedAt =
  PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt;

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt {
  alias: string;
  team_name: string;
}

export type CreateTeamNameAliasBody =
  PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt;

/**
 * Construct a type with the properties of T except for those in type K.
 */
export type OmitTeamNameAliasIdOrCreatedAtOrUpdatedAt =
  PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt;

export interface TeamNameAlias {
  alias: string;
  created_at: string;
  id: string;
  team_name: string;
  updated_at: string;
}

export type TipAnalysisRiskLevel =
  (typeof TipAnalysisRiskLevel)[keyof typeof TipAnalysisRiskLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TipAnalysisRiskLevel = {
  High: "High",
  Moderate: "Moderate",
  Low: "Low",
} as const;

export interface TipAnalysisRiskItem {
  level: TipAnalysisRiskLevel;
  reasoning: string;
  title: string;
}

export interface TipAnalysisItem {
  id: string;
  reasoning: string;
  risks: TipAnalysisRiskItem[];
}

export interface GetInitialFiltersQueryParams {
  from?: string;
  to?: string;
  type: TipType;
}

/**
 * Construct a type with the properties of T except for those in type K.
 */
export type OmitGetTipsQueryParamsType =
  PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsType;

export interface GetTopPerformersQueryParams {
  from?: string;
  to?: string;
  type: TipType;
}

export interface PerformerPropsRatings {
  avg_odds: number;
  hit_rate: number;
  key: Ratings;
  profit_loss: number;
  roi: number;
  total_tips: number;
}

export interface PerformerPropsCategory {
  avg_odds: number;
  hit_rate: number;
  key: Category;
  profit_loss: number;
  roi: number;
  total_tips: number;
}

export interface PerformerPropsLeague {
  avg_odds: number;
  hit_rate: number;
  key: League;
  profit_loss: number;
  roi: number;
  total_tips: number;
}

export interface TopPerformers {
  by_category: PerformerPropsCategory[];
  by_league: PerformerPropsLeague[];
  by_rating: PerformerPropsRatings[];
}

export type TipSummaryBankrollOverTime = {
  default: BankrollStrategySummaryRecord;
  flat_betting: BankrollStrategySummaryRecord;
  kelly_criterion: BankrollStrategySummaryRecord;
};

export interface TipSummary {
  avg_bet_amount: number;
  avg_odds: number;
  avg_probability: number;
  bankrollOverTime: TipSummaryBankrollOverTime;
  hit_rate: number;
  profit_loss: number;
  roi: number;
  total_lost: number;
  total_matches: number;
  total_push: number;
  total_staked: number;
  total_tips: number;
  total_unsettled: number;
  total_won: number;
}

export interface GetTipsPerformanceQueryParams {
  best_choice?: boolean;
  from?: string;
  include_categories?: Category[];
  include_leagues?: number[];
  include_ratings?: Ratings[];
  max_edge?: number;
  max_match_importance?: number;
  max_odds?: number;
  max_predictability?: number;
  max_probability?: number;
  max_stake?: number;
  min_edge?: number;
  min_match_importance?: number;
  min_odds?: number;
  min_predictability?: number;
  min_probability?: number;
  min_stake?: number;
  page?: number;
  pageSize?: number;
  safe_to_bet?: boolean;
  season?: number;
  sort?: SortOrder;
  to?: string;
  type: TipType;
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickGetTipsMatchesQueryParamsExcludeKeyofGetTipsMatchesQueryParamsIncludeMatchStatusOrIncludeTipStatus {
  best_choice?: boolean;
  from?: string;
  include_categories?: Category[];
  include_leagues?: number[];
  include_ratings?: Ratings[];
  max_edge?: number;
  max_match_importance?: number;
  max_odds?: number;
  max_predictability?: number;
  max_probability?: number;
  max_stake?: number;
  min_edge?: number;
  min_match_importance?: number;
  min_odds?: number;
  min_predictability?: number;
  min_probability?: number;
  min_stake?: number;
  page?: number;
  pageSize?: number;
  safe_to_bet?: boolean;
  season?: number;
  sort?: SortOrder;
  to?: string;
  type: TipType;
}

export interface TipsWithFixture {
  fixture: FixtureItem;
  tips: Tip[];
}

export interface TipsWithFixtureResponse {
  currentPage: number;
  data: TipsWithFixture[];
  totalItems: number;
  totalPages: number;
  totalTips: number;
}

export interface Currency {
  code: string;
  locale: string;
  name: string;
  symbol: string;
}

export interface UserPreferences {
  allowResultOrientedV2?: boolean;
  currency: Currency;
  dismissedTelegramPermanently: boolean;
  hasActiveSubscription: boolean;
  id: string;
  kellyFraction: number;
  telegramDismissedAt?: number;
  telegramJoined: boolean;
  telegramStale?: boolean;
}

export interface UpdateTelegramBody {
  dismissedTelegramPermanently: boolean;
  telegramJoined: boolean;
}

export interface UpdateTelegramPreferencesResponse {
  error?: string;
  success: boolean;
}

export interface CreateUserSourceBody {
  source: string;
  utm_source: string;
}

export interface UserSource {
  created_at?: string;
  id: string;
  source: string;
  utm_source: string;
}

export interface UserTipBody {
  bet_amount: number;
  id: string;
  odds: number;
}

export interface GetUserTipsSummaryResponse {
  avg_bet_amount: number;
  avg_odds: number;
  avg_probability: number;
  bankrollOverTime: GetUserTipsSummaryResponseBankrollOverTime;
  hit_rate: number;
  profit_loss: number;
  roi: number;
  total_lost: number;
  total_matches: number;
  total_push: number;
  total_staked: number;
  total_tips: number;
  total_unsettled: number;
  total_won: number;
}

export interface BankrollStrategySummaryDataItem {
  bankroll: number;
  date: string;
  tip_id: string;
}

export interface BankrollStrategySummaryRecord {
  bankroll: number;
  data: BankrollStrategySummaryDataItem[];
  hit_rate: number;
  name: string;
  profit_loss: number;
  roi_percent: number;
  total_evaluated_bets: number;
  total_lost: number;
  total_push: number;
  total_staked: number;
  total_unsettled: number;
  total_won: number;
}

export type GetUserTipsSummaryResponseBankrollOverTime = {
  default: BankrollStrategySummaryRecord;
  flat_betting: BankrollStrategySummaryRecord;
  kelly_criterion: BankrollStrategySummaryRecord;
};

export type SortOrder = (typeof SortOrder)[keyof typeof SortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortOrder = {
  asc: "asc",
  desc: "desc",
} as const;

export type TipStatus = (typeof TipStatus)[keyof typeof TipStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TipStatus = {
  won: "won",
  lost: "lost",
  push: "push",
  unsettled: "unsettled",
} as const;

export type MatchStatus = (typeof MatchStatus)[keyof typeof MatchStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MatchStatus = {
  live: "live",
  "not-started": "not-started",
  finished: "finished",
} as const;

export interface UserTipsWithFixtureResponse {
  currentPage: number;
  data: UserTipsWithFixture[];
  totalItems: number;
  totalPages: number;
  totalTips: number;
}

export type TipType = (typeof TipType)[keyof typeof TipType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TipType = {
  value: "value",
  resultOriented: "resultOriented",
} as const;

export interface GetTipsMatchesQueryParams {
  best_choice?: boolean;
  from?: string;
  include_categories?: Category[];
  include_leagues?: number[];
  include_match_status?: MatchStatus[];
  include_ratings?: Ratings[];
  include_tip_status?: TipStatus[];
  max_edge?: number;
  max_match_importance?: number;
  max_odds?: number;
  max_predictability?: number;
  max_probability?: number;
  max_stake?: number;
  min_edge?: number;
  min_match_importance?: number;
  min_odds?: number;
  min_predictability?: number;
  min_probability?: number;
  min_stake?: number;
  page?: number;
  pageSize?: number;
  safe_to_bet?: boolean;
  season?: number;
  sort?: SortOrder;
  to?: string;
  type: TipType;
}

export type UserTipAllOf = {
  currency: string;
  type: TipType;
  user_id: string;
};

export type UserTip = Tip & UserTipAllOf;

export interface UserTipsWithFixture {
  fixture: FixtureItem;
  tips: UserTip[];
}

export type Category = (typeof Category)[keyof typeof Category];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Category = {
  Match_Winner: "Match Winner",
  "First-Half_Result": "First-Half Result",
  "Second-Half_Result": "Second-Half Result",
  "Total_Goals_Over/Under": "Total Goals Over/Under",
  "Home_Goals_Over/Under": "Home Goals Over/Under",
  "Away_Goals_Over/Under": "Away Goals Over/Under",
  Both_Teams_to_Score: "Both Teams to Score",
  Player_to_Score: "Player to Score",
  Player_to_Score_or_Assist: "Player to Score or Assist",
  "Total_Corners_Over/Under": "Total Corners Over/Under",
  "Home_Corners_Over/Under": "Home Corners Over/Under",
  "Away_Corners_Over/Under": "Away Corners Over/Under",
  "Total_Cards_Over/Under": "Total Cards Over/Under",
  "Home_Cards_Over/Under": "Home Cards Over/Under",
  "Away_Cards_Over/Under": "Away Cards Over/Under",
  Asian_Handicap: "Asian Handicap",
  Draw_No_Bet: "Draw No Bet",
  Double_Chance: "Double Chance",
  Correct_Score: "Correct Score",
  Team_to_Score_First: "Team to Score First",
  Both_Teams_to_Score_in_Both_Halves: "Both Teams to Score in Both Halves",
  Team_to_Score_in_Both_Halves: "Team to Score in Both Halves",
  Clean_Sheet: "Clean Sheet",
  Win_to_Nil: "Win to Nil",
  Penalty_Awarded: "Penalty Awarded",
  Red_Card_Shown: "Red Card Shown",
} as const;

export type Ratings = (typeof Ratings)[keyof typeof Ratings];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Ratings = {
  super_safe: "super safe",
  safe: "safe",
  value: "value",
  risky: "risky",
} as const;

export interface GetTipsSummaryQueryParams {
  best_choice?: boolean;
  from?: string;
  include_categories?: Category[];
  include_leagues?: number[];
  include_ratings?: Ratings[];
  initialBankroll?: number;
  kellyFraction?: number;
  max_edge?: number;
  max_match_importance?: number;
  max_odds?: number;
  max_predictability?: number;
  max_probability?: number;
  max_stake?: number;
  min_edge?: number;
  min_match_importance?: number;
  min_odds?: number;
  min_predictability?: number;
  min_probability?: number;
  min_stake?: number;
  safe_to_bet?: boolean;
  season?: number;
  sort?: SortOrder;
  to?: string;
  type: TipType;
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsIncludeTipStatus {
  best_choice?: boolean;
  from?: string;
  include_categories?: Category[];
  include_leagues?: number[];
  include_ratings?: Ratings[];
  max_edge?: number;
  max_match_importance?: number;
  max_odds?: number;
  max_predictability?: number;
  max_probability?: number;
  max_stake?: number;
  min_edge?: number;
  min_match_importance?: number;
  min_odds?: number;
  min_predictability?: number;
  min_probability?: number;
  min_stake?: number;
  safe_to_bet?: boolean;
  season?: number;
  sort?: SortOrder;
  to?: string;
  type: TipType;
}

export interface GetUpcomingTipsQueryParams {
  best_choice?: boolean;
  from?: string;
  include_categories?: Category[];
  include_leagues?: number[];
  include_ratings?: Ratings[];
  max_edge?: number;
  max_match_importance?: number;
  max_odds?: number;
  max_predictability?: number;
  max_probability?: number;
  max_stake?: number;
  min_edge?: number;
  min_match_importance?: number;
  min_odds?: number;
  min_predictability?: number;
  min_probability?: number;
  min_stake?: number;
  page?: number;
  pageSize?: number;
  safe_to_bet?: boolean;
  season?: number;
  sort?: SortOrder;
  to?: string;
  type: TipType;
}

export interface GetUserTipsQueryParams {
  best_choice?: boolean;
  from?: string;
  include_categories?: Category[];
  include_leagues?: number[];
  include_ratings?: Ratings[];
  include_tip_status?: TipStatus[];
  max_edge?: number;
  max_match_importance?: number;
  max_odds?: number;
  max_predictability?: number;
  max_probability?: number;
  max_stake?: number;
  min_edge?: number;
  min_match_importance?: number;
  min_odds?: number;
  min_predictability?: number;
  min_probability?: number;
  min_stake?: number;
  safe_to_bet?: boolean;
  season?: number;
  sort?: SortOrder;
  to?: string;
  type?: TipType;
}

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsType {
  best_choice?: boolean;
  from?: string;
  include_categories?: Category[];
  include_leagues?: number[];
  include_ratings?: Ratings[];
  include_tip_status?: TipStatus[];
  max_edge?: number;
  max_match_importance?: number;
  max_odds?: number;
  max_predictability?: number;
  max_probability?: number;
  max_stake?: number;
  min_edge?: number;
  min_match_importance?: number;
  min_odds?: number;
  min_predictability?: number;
  min_probability?: number;
  min_stake?: number;
  safe_to_bet?: boolean;
  season?: number;
  sort?: SortOrder;
  to?: string;
}

export interface Tip {
  best_choice: boolean;
  bet_amount: number;
  category: Category;
  edge: number;
  fixture_id: string;
  id: string;
  league_id: number;
  name: string;
  odds: number;
  probability: number;
  rating: Ratings;
  reasoning: string;
  season: number;
  start_date: string;
  /** @nullable */
  winner?: boolean | null;
}

export interface Score {
  /** @nullable */
  away: number | null;
  /** @nullable */
  home: number | null;
}

export interface ScoreRecord {
  extratime: Score;
  fulltime: Score;
  halftime: Score;
  penalty: Score;
}

export interface Team {
  id: number;
  logo: string;
  name: string;
  /** @nullable */
  winner: boolean | null;
}

export interface TeamsRecord {
  away: Team;
  home: Team;
}

export interface League {
  country: string;
  flag: string;
  id: number;
  logo: string;
  name: string;
  round?: string;
  season: number;
}

export interface FixtureItem {
  fixture: Fixture;
  goals: Score;
  league: League;
  score: ScoreRecord;
  teams: TeamsRecord;
}

export type FixtureVenue = {
  city: string;
  /** @nullable */
  id: number | null;
  name: string;
};

export type FixturePeriods = {
  first: number;
  second: number;
};

export interface FixtureStatusResponse {
  elapsed: number;
  long: string;
  short: string;
}

export interface Fixture {
  date: string;
  id: number;
  periods: FixturePeriods;
  referee: string;
  status: FixtureStatusResponse;
  timestamp: number;
  timezone: string;
  venue: FixtureVenue;
}

export const getUserTips = (
  params: GetUserTipsParams,
  signal?: AbortSignal,
) => {
  return customInstance<UserTipsWithFixtureResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/user-tips`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetUserTipsQueryKey = (params: GetUserTipsParams) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/user-tips`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetUserTipsQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUserTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTips>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserTipsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserTips>>> = ({
    signal,
  }) => getUserTips(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserTips>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserTipsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserTips>>
>;
export type GetUserTipsQueryError = ErrorType<unknown>;

export function useGetUserTips<
  TData = Awaited<ReturnType<typeof getUserTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUserTipsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTips>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserTips>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserTips<
  TData = Awaited<ReturnType<typeof getUserTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUserTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTips>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserTips>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserTips<
  TData = Awaited<ReturnType<typeof getUserTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUserTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTips>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserTips<
  TData = Awaited<ReturnType<typeof getUserTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUserTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTips>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserTipsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createUserTip = (userTipBody: UserTipBody) => {
  return customInstance<void>({
    url: `https://api.football-genie.com/o1-typescript-service/user-tips`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: userTipBody,
  });
};

export const getCreateUserTipMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserTip>>,
    TError,
    { data: UserTipBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUserTip>>,
  TError,
  { data: UserTipBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUserTip>>,
    { data: UserTipBody }
  > = (props) => {
    const { data } = props ?? {};

    return createUserTip(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserTipMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUserTip>>
>;
export type CreateUserTipMutationBody = UserTipBody;
export type CreateUserTipMutationError = ErrorType<unknown>;

export const useCreateUserTip = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserTip>>,
    TError,
    { data: UserTipBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUserTip>>,
  TError,
  { data: UserTipBody },
  TContext
> => {
  const mutationOptions = getCreateUserTipMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getUserTipsSummary = (
  params?: GetUserTipsSummaryParams,
  signal?: AbortSignal,
) => {
  return customInstance<GetUserTipsSummaryResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/user-tips/summary`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetUserTipsSummaryQueryKey = (
  params?: GetUserTipsSummaryParams,
) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/user-tips/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetUserTipsSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params?: GetUserTipsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserTipsSummary>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserTipsSummaryQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserTipsSummary>>
  > = ({ signal }) => getUserTipsSummary(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserTipsSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserTipsSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserTipsSummary>>
>;
export type GetUserTipsSummaryQueryError = ErrorType<unknown>;

export function useGetUserTipsSummary<
  TData = Awaited<ReturnType<typeof getUserTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | GetUserTipsSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserTipsSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserTipsSummary>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserTipsSummary<
  TData = Awaited<ReturnType<typeof getUserTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params?: GetUserTipsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserTipsSummary>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserTipsSummary>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserTipsSummary<
  TData = Awaited<ReturnType<typeof getUserTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params?: GetUserTipsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserTipsSummary>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserTipsSummary<
  TData = Awaited<ReturnType<typeof getUserTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params?: GetUserTipsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUserTipsSummary>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserTipsSummaryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUserTip = (id: string, signal?: AbortSignal) => {
  return customInstance<UserTip>({
    url: `https://api.football-genie.com/o1-typescript-service/user-tips/${id}`,
    method: "GET",
    signal,
  });
};

export const getGetUserTipQueryKey = (id: string) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/user-tips/${id}`,
  ] as const;
};

export const getGetUserTipQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserTip>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTip>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserTipQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserTip>>> = ({
    signal,
  }) => getUserTip(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserTip>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserTipQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserTip>>
>;
export type GetUserTipQueryError = ErrorType<unknown>;

export function useGetUserTip<
  TData = Awaited<ReturnType<typeof getUserTip>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTip>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserTip>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserTip<
  TData = Awaited<ReturnType<typeof getUserTip>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTip>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserTip>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserTip<
  TData = Awaited<ReturnType<typeof getUserTip>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTip>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserTip<
  TData = Awaited<ReturnType<typeof getUserTip>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUserTip>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserTipQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const deleteUserTip = (id: string) => {
  return customInstance<DeleteUserTip200>({
    url: `https://api.football-genie.com/o1-typescript-service/user-tips/${id}`,
    method: "DELETE",
  });
};

export const getDeleteUserTipMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserTip>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUserTip>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUserTip>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteUserTip(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserTipMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserTip>>
>;

export type DeleteUserTipMutationError = ErrorType<unknown>;

export const useDeleteUserTip = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUserTip>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUserTip>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteUserTipMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getUserSource = (signal?: AbortSignal) => {
  return customInstance<UserSource>({
    url: `https://api.football-genie.com/o1-typescript-service/user-source`,
    method: "GET",
    signal,
  });
};

export const getGetUserSourceQueryKey = () => {
  return [
    `https://api.football-genie.com/o1-typescript-service/user-source`,
  ] as const;
};

export const getGetUserSourceQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserSource>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserSource>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSourceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSource>>> = ({
    signal,
  }) => getUserSource(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserSource>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSourceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserSource>>
>;
export type GetUserSourceQueryError = ErrorType<unknown>;

export function useGetUserSource<
  TData = Awaited<ReturnType<typeof getUserSource>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserSource>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserSource>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserSource<
  TData = Awaited<ReturnType<typeof getUserSource>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserSource>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserSource>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserSource<
  TData = Awaited<ReturnType<typeof getUserSource>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserSource>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserSource<
  TData = Awaited<ReturnType<typeof getUserSource>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUserSource>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserSourceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createUserSource = (
  createUserSourceBody: CreateUserSourceBody,
) => {
  return customInstance<UserSource>({
    url: `https://api.football-genie.com/o1-typescript-service/user-source`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createUserSourceBody,
  });
};

export const getCreateUserSourceMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserSource>>,
    TError,
    { data: CreateUserSourceBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUserSource>>,
  TError,
  { data: CreateUserSourceBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUserSource>>,
    { data: CreateUserSourceBody }
  > = (props) => {
    const { data } = props ?? {};

    return createUserSource(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserSourceMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUserSource>>
>;
export type CreateUserSourceMutationBody = CreateUserSourceBody;
export type CreateUserSourceMutationError = ErrorType<unknown>;

export const useCreateUserSource = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserSource>>,
    TError,
    { data: CreateUserSourceBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUserSource>>,
  TError,
  { data: CreateUserSourceBody },
  TContext
> => {
  const mutationOptions = getCreateUserSourceMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateTelegramPreferences = (
  updateTelegramBody: UpdateTelegramBody,
) => {
  return customInstance<UpdateTelegramPreferencesResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/userPreferences/telegram-preferences`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateTelegramBody,
  });
};

export const getUpdateTelegramPreferencesMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTelegramPreferences>>,
    TError,
    { data: UpdateTelegramBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTelegramPreferences>>,
  TError,
  { data: UpdateTelegramBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTelegramPreferences>>,
    { data: UpdateTelegramBody }
  > = (props) => {
    const { data } = props ?? {};

    return updateTelegramPreferences(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTelegramPreferencesMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTelegramPreferences>>
>;
export type UpdateTelegramPreferencesMutationBody = UpdateTelegramBody;
export type UpdateTelegramPreferencesMutationError = ErrorType<unknown>;

export const useUpdateTelegramPreferences = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTelegramPreferences>>,
    TError,
    { data: UpdateTelegramBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTelegramPreferences>>,
  TError,
  { data: UpdateTelegramBody },
  TContext
> => {
  const mutationOptions = getUpdateTelegramPreferencesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getUserPreferences = (signal?: AbortSignal) => {
  return customInstance<UserPreferences>({
    url: `https://api.football-genie.com/o1-typescript-service/userPreferences`,
    method: "GET",
    signal,
  });
};

export const getGetUserPreferencesQueryKey = () => {
  return [
    `https://api.football-genie.com/o1-typescript-service/userPreferences`,
  ] as const;
};

export const getGetUserPreferencesQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserPreferences>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUserPreferences>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserPreferencesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserPreferences>>
  > = ({ signal }) => getUserPreferences(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserPreferences>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserPreferencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserPreferences>>
>;
export type GetUserPreferencesQueryError = ErrorType<unknown>;

export function useGetUserPreferences<
  TData = Awaited<ReturnType<typeof getUserPreferences>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUserPreferences>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserPreferences>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserPreferences<
  TData = Awaited<ReturnType<typeof getUserPreferences>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUserPreferences>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getUserPreferences>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUserPreferences<
  TData = Awaited<ReturnType<typeof getUserPreferences>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUserPreferences>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUserPreferences<
  TData = Awaited<ReturnType<typeof getUserPreferences>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getUserPreferences>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserPreferencesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getTips = (params: GetTipsParams, signal?: AbortSignal) => {
  return customInstance<TipsWithFixtureResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/tips`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetTipsQueryKey = (params: GetTipsParams) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/tips`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetTipsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTips>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTipsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTips>>> = ({
    signal,
  }) => getTips(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTips>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTipsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTips>>
>;
export type GetTipsQueryError = ErrorType<unknown>;

export function useGetTips<
  TData = Awaited<ReturnType<typeof getTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTips>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTips>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTips<
  TData = Awaited<ReturnType<typeof getTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTips>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTips>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTips<
  TData = Awaited<ReturnType<typeof getTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTips>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTips<
  TData = Awaited<ReturnType<typeof getTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTips>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTipsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getTipsPerformance = (
  params: GetTipsPerformanceParams,
  signal?: AbortSignal,
) => {
  return customInstance<TipsWithFixtureResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/tips/performance`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetTipsPerformanceQueryKey = (
  params: GetTipsPerformanceParams,
) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/tips/performance`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetTipsPerformanceQueryOptions = <
  TData = Awaited<ReturnType<typeof getTipsPerformance>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsPerformanceParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTipsPerformance>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTipsPerformanceQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTipsPerformance>>
  > = ({ signal }) => getTipsPerformance(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTipsPerformance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTipsPerformanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTipsPerformance>>
>;
export type GetTipsPerformanceQueryError = ErrorType<unknown>;

export function useGetTipsPerformance<
  TData = Awaited<ReturnType<typeof getTipsPerformance>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsPerformanceParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTipsPerformance>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTipsPerformance>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTipsPerformance<
  TData = Awaited<ReturnType<typeof getTipsPerformance>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsPerformanceParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTipsPerformance>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTipsPerformance>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTipsPerformance<
  TData = Awaited<ReturnType<typeof getTipsPerformance>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsPerformanceParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTipsPerformance>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTipsPerformance<
  TData = Awaited<ReturnType<typeof getTipsPerformance>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsPerformanceParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTipsPerformance>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTipsPerformanceQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUpcomingTips = (
  params: GetUpcomingTipsParams,
  signal?: AbortSignal,
) => {
  return customInstance<TipsWithFixtureResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/tips/upcoming-tips`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetUpcomingTipsQueryKey = (params: GetUpcomingTipsParams) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/tips/upcoming-tips`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetUpcomingTipsQueryOptions = <
  TData = Awaited<ReturnType<typeof getUpcomingTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUpcomingTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUpcomingTips>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUpcomingTipsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUpcomingTips>>> = ({
    signal,
  }) => getUpcomingTips(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUpcomingTips>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUpcomingTipsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpcomingTips>>
>;
export type GetUpcomingTipsQueryError = ErrorType<unknown>;

export function useGetUpcomingTips<
  TData = Awaited<ReturnType<typeof getUpcomingTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUpcomingTipsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUpcomingTips>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUpcomingTips>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUpcomingTips<
  TData = Awaited<ReturnType<typeof getUpcomingTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUpcomingTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUpcomingTips>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUpcomingTips>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetUpcomingTips<
  TData = Awaited<ReturnType<typeof getUpcomingTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUpcomingTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUpcomingTips>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetUpcomingTips<
  TData = Awaited<ReturnType<typeof getUpcomingTips>>,
  TError = ErrorType<unknown>,
>(
  params: GetUpcomingTipsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getUpcomingTips>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUpcomingTipsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getTipsSummary = (
  params: GetTipsSummaryParams,
  signal?: AbortSignal,
) => {
  return customInstance<TipSummary>({
    url: `https://api.football-genie.com/o1-typescript-service/tips/summary`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetTipsSummaryQueryKey = (params: GetTipsSummaryParams) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/tips/summary`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetTipsSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipsSummary>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTipsSummaryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTipsSummary>>> = ({
    signal,
  }) => getTipsSummary(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTipsSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTipsSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTipsSummary>>
>;
export type GetTipsSummaryQueryError = ErrorType<unknown>;

export function useGetTipsSummary<
  TData = Awaited<ReturnType<typeof getTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsSummaryParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipsSummary>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTipsSummary>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTipsSummary<
  TData = Awaited<ReturnType<typeof getTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipsSummary>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTipsSummary>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTipsSummary<
  TData = Awaited<ReturnType<typeof getTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipsSummary>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTipsSummary<
  TData = Awaited<ReturnType<typeof getTipsSummary>>,
  TError = ErrorType<unknown>,
>(
  params: GetTipsSummaryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipsSummary>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTipsSummaryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getTopPerformers = (
  params: GetTopPerformersParams,
  signal?: AbortSignal,
) => {
  return customInstance<TopPerformers>({
    url: `https://api.football-genie.com/o1-typescript-service/tips/top-performers`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetTopPerformersQueryKey = (params: GetTopPerformersParams) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/tips/top-performers`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetTopPerformersQueryOptions = <
  TData = Awaited<ReturnType<typeof getTopPerformers>>,
  TError = ErrorType<unknown>,
>(
  params: GetTopPerformersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTopPerformers>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTopPerformersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTopPerformers>>
  > = ({ signal }) => getTopPerformers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTopPerformers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTopPerformersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTopPerformers>>
>;
export type GetTopPerformersQueryError = ErrorType<unknown>;

export function useGetTopPerformers<
  TData = Awaited<ReturnType<typeof getTopPerformers>>,
  TError = ErrorType<unknown>,
>(
  params: GetTopPerformersParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTopPerformers>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTopPerformers>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTopPerformers<
  TData = Awaited<ReturnType<typeof getTopPerformers>>,
  TError = ErrorType<unknown>,
>(
  params: GetTopPerformersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTopPerformers>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTopPerformers>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTopPerformers<
  TData = Awaited<ReturnType<typeof getTopPerformers>>,
  TError = ErrorType<unknown>,
>(
  params: GetTopPerformersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTopPerformers>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTopPerformers<
  TData = Awaited<ReturnType<typeof getTopPerformers>>,
  TError = ErrorType<unknown>,
>(
  params: GetTopPerformersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getTopPerformers>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTopPerformersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const initialFiltersGet = (
  params: InitialFiltersGetParams,
  signal?: AbortSignal,
) => {
  return customInstance<PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsType>(
    {
      url: `https://api.football-genie.com/o1-typescript-service/tips/initial-filters`,
      method: "GET",
      params,
      signal,
    },
  );
};

export const getInitialFiltersGetQueryKey = (
  params: InitialFiltersGetParams,
) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/tips/initial-filters`,
    ...(params ? [params] : []),
  ] as const;
};

export const getInitialFiltersGetQueryOptions = <
  TData = Awaited<ReturnType<typeof initialFiltersGet>>,
  TError = ErrorType<unknown>,
>(
  params: InitialFiltersGetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof initialFiltersGet>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getInitialFiltersGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof initialFiltersGet>>
  > = ({ signal }) => initialFiltersGet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof initialFiltersGet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InitialFiltersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof initialFiltersGet>>
>;
export type InitialFiltersGetQueryError = ErrorType<unknown>;

export function useInitialFiltersGet<
  TData = Awaited<ReturnType<typeof initialFiltersGet>>,
  TError = ErrorType<unknown>,
>(
  params: InitialFiltersGetParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof initialFiltersGet>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof initialFiltersGet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInitialFiltersGet<
  TData = Awaited<ReturnType<typeof initialFiltersGet>>,
  TError = ErrorType<unknown>,
>(
  params: InitialFiltersGetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof initialFiltersGet>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof initialFiltersGet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useInitialFiltersGet<
  TData = Awaited<ReturnType<typeof initialFiltersGet>>,
  TError = ErrorType<unknown>,
>(
  params: InitialFiltersGetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof initialFiltersGet>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useInitialFiltersGet<
  TData = Awaited<ReturnType<typeof initialFiltersGet>>,
  TError = ErrorType<unknown>,
>(
  params: InitialFiltersGetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof initialFiltersGet>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getInitialFiltersGetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getTipAnalysis = (id: string, signal?: AbortSignal) => {
  return customInstance<TipAnalysisItem>({
    url: `https://api.football-genie.com/o1-typescript-service/tip-analysis/${id}`,
    method: "GET",
    signal,
  });
};

export const getGetTipAnalysisQueryKey = (id: string) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/tip-analysis/${id}`,
  ] as const;
};

export const getGetTipAnalysisQueryOptions = <
  TData = Awaited<ReturnType<typeof getTipAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipAnalysis>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTipAnalysisQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTipAnalysis>>> = ({
    signal,
  }) => getTipAnalysis(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTipAnalysis>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTipAnalysisQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTipAnalysis>>
>;
export type GetTipAnalysisQueryError = ErrorType<unknown>;

export function useGetTipAnalysis<
  TData = Awaited<ReturnType<typeof getTipAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipAnalysis>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTipAnalysis>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTipAnalysis<
  TData = Awaited<ReturnType<typeof getTipAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipAnalysis>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTipAnalysis>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTipAnalysis<
  TData = Awaited<ReturnType<typeof getTipAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipAnalysis>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTipAnalysis<
  TData = Awaited<ReturnType<typeof getTipAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTipAnalysis>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTipAnalysisQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getTeamNameAliases = (signal?: AbortSignal) => {
  return customInstance<TeamNameAlias[]>({
    url: `https://api.football-genie.com/o1-typescript-service/team-name-aliases`,
    method: "GET",
    signal,
  });
};

export const getGetTeamNameAliasesQueryKey = () => {
  return [
    `https://api.football-genie.com/o1-typescript-service/team-name-aliases`,
  ] as const;
};

export const getGetTeamNameAliasesQueryOptions = <
  TData = Awaited<ReturnType<typeof getTeamNameAliases>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getTeamNameAliases>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamNameAliasesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTeamNameAliases>>
  > = ({ signal }) => getTeamNameAliases(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeamNameAliases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTeamNameAliasesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeamNameAliases>>
>;
export type GetTeamNameAliasesQueryError = ErrorType<unknown>;

export function useGetTeamNameAliases<
  TData = Awaited<ReturnType<typeof getTeamNameAliases>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getTeamNameAliases>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getTeamNameAliases>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTeamNameAliases<
  TData = Awaited<ReturnType<typeof getTeamNameAliases>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getTeamNameAliases>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getTeamNameAliases>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTeamNameAliases<
  TData = Awaited<ReturnType<typeof getTeamNameAliases>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getTeamNameAliases>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetTeamNameAliases<
  TData = Awaited<ReturnType<typeof getTeamNameAliases>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getTeamNameAliases>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTeamNameAliasesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createTeamNameAlias = (
  pickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt: PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt,
) => {
  return customInstance<TeamNameAlias>({
    url: `https://api.football-genie.com/o1-typescript-service/team-name-aliases`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: pickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt,
  });
};

export const getCreateTeamNameAliasMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTeamNameAlias>>,
    TError,
    {
      data: PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTeamNameAlias>>,
  TError,
  { data: PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTeamNameAlias>>,
    { data: PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt }
  > = (props) => {
    const { data } = props ?? {};

    return createTeamNameAlias(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTeamNameAliasMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTeamNameAlias>>
>;
export type CreateTeamNameAliasMutationBody =
  PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt;
export type CreateTeamNameAliasMutationError = ErrorType<unknown>;

export const useCreateTeamNameAlias = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTeamNameAlias>>,
    TError,
    {
      data: PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTeamNameAlias>>,
  TError,
  { data: PickTeamNameAliasExcludeKeyofTeamNameAliasIdOrCreatedAtOrUpdatedAt },
  TContext
> => {
  const mutationOptions = getCreateTeamNameAliasMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateTeamNameAlias = (
  pickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt: PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt,
) => {
  return customInstance<TeamNameAlias>({
    url: `https://api.football-genie.com/o1-typescript-service/team-name-aliases`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: pickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt,
  });
};

export const getUpdateTeamNameAliasMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeamNameAlias>>,
    TError,
    { data: PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTeamNameAlias>>,
  TError,
  { data: PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTeamNameAlias>>,
    { data: PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt }
  > = (props) => {
    const { data } = props ?? {};

    return updateTeamNameAlias(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTeamNameAliasMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTeamNameAlias>>
>;
export type UpdateTeamNameAliasMutationBody =
  PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt;
export type UpdateTeamNameAliasMutationError = ErrorType<unknown>;

export const useUpdateTeamNameAlias = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTeamNameAlias>>,
    TError,
    { data: PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTeamNameAlias>>,
  TError,
  { data: PickTeamNameAliasExcludeKeyofTeamNameAliasCreatedAtOrUpdatedAt },
  TContext
> => {
  const mutationOptions = getUpdateTeamNameAliasMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteTeamNameAlias = (id: string) => {
  return customInstance<TeamNameAlias>({
    url: `https://api.football-genie.com/o1-typescript-service/team-name-aliases/${id}`,
    method: "DELETE",
  });
};

export const getDeleteTeamNameAliasMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTeamNameAlias>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTeamNameAlias>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTeamNameAlias>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteTeamNameAlias(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTeamNameAliasMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTeamNameAlias>>
>;

export type DeleteTeamNameAliasMutationError = ErrorType<unknown>;

export const useDeleteTeamNameAlias = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTeamNameAlias>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTeamNameAlias>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteTeamNameAliasMutationOptions(options);

  return useMutation(mutationOptions);
};

export const createStrategy = (createStrategyBody: CreateStrategyBody) => {
  return customInstance<CreateStrategyResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/strategy/create`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: createStrategyBody,
  });
};

export const getCreateStrategyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStrategy>>,
    TError,
    { data: CreateStrategyBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createStrategy>>,
  TError,
  { data: CreateStrategyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createStrategy>>,
    { data: CreateStrategyBody }
  > = (props) => {
    const { data } = props ?? {};

    return createStrategy(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateStrategyMutationResult = NonNullable<
  Awaited<ReturnType<typeof createStrategy>>
>;
export type CreateStrategyMutationBody = CreateStrategyBody;
export type CreateStrategyMutationError = ErrorType<unknown>;

export const useCreateStrategy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStrategy>>,
    TError,
    { data: CreateStrategyBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createStrategy>>,
  TError,
  { data: CreateStrategyBody },
  TContext
> => {
  const mutationOptions = getCreateStrategyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateStrategy = (updateStrategyBody: UpdateStrategyBody) => {
  return customInstance<UpdateStrategyResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/strategy/update`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: updateStrategyBody,
  });
};

export const getUpdateStrategyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStrategy>>,
    TError,
    { data: UpdateStrategyBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStrategy>>,
  TError,
  { data: UpdateStrategyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStrategy>>,
    { data: UpdateStrategyBody }
  > = (props) => {
    const { data } = props ?? {};

    return updateStrategy(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateStrategyMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStrategy>>
>;
export type UpdateStrategyMutationBody = UpdateStrategyBody;
export type UpdateStrategyMutationError = ErrorType<unknown>;

export const useUpdateStrategy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStrategy>>,
    TError,
    { data: UpdateStrategyBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateStrategy>>,
  TError,
  { data: UpdateStrategyBody },
  TContext
> => {
  const mutationOptions = getUpdateStrategyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getStrategies = (signal?: AbortSignal) => {
  return customInstance<Strategy[]>({
    url: `https://api.football-genie.com/o1-typescript-service/strategy/strategies`,
    method: "GET",
    signal,
  });
};

export const getGetStrategiesQueryKey = () => {
  return [
    `https://api.football-genie.com/o1-typescript-service/strategy/strategies`,
  ] as const;
};

export const getGetStrategiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getStrategies>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getStrategies>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStrategiesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStrategies>>> = ({
    signal,
  }) => getStrategies(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getStrategies>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetStrategiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStrategies>>
>;
export type GetStrategiesQueryError = ErrorType<unknown>;

export function useGetStrategies<
  TData = Awaited<ReturnType<typeof getStrategies>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getStrategies>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getStrategies>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetStrategies<
  TData = Awaited<ReturnType<typeof getStrategies>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getStrategies>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getStrategies>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetStrategies<
  TData = Awaited<ReturnType<typeof getStrategies>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getStrategies>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetStrategies<
  TData = Awaited<ReturnType<typeof getStrategies>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getStrategies>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetStrategiesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const deleteStrategy = (id: string) => {
  return customInstance<Strategy>({
    url: `https://api.football-genie.com/o1-typescript-service/strategy/delete/${id}`,
    method: "DELETE",
  });
};

export const getDeleteStrategyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteStrategy>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteStrategy>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteStrategy>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteStrategy(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteStrategyMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteStrategy>>
>;

export type DeleteStrategyMutationError = ErrorType<unknown>;

export const useDeleteStrategy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteStrategy>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteStrategy>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteStrategyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getStrategy = (id: string, signal?: AbortSignal) => {
  return customInstance<Strategy>({
    url: `https://api.football-genie.com/o1-typescript-service/strategy/${id}`,
    method: "GET",
    signal,
  });
};

export const getGetStrategyQueryKey = (id: string) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/strategy/${id}`,
  ] as const;
};

export const getGetStrategyQueryOptions = <
  TData = Awaited<ReturnType<typeof getStrategy>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getStrategy>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStrategyQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStrategy>>> = ({
    signal,
  }) => getStrategy(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getStrategy>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetStrategyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStrategy>>
>;
export type GetStrategyQueryError = ErrorType<unknown>;

export function useGetStrategy<
  TData = Awaited<ReturnType<typeof getStrategy>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getStrategy>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getStrategy>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetStrategy<
  TData = Awaited<ReturnType<typeof getStrategy>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getStrategy>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getStrategy>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetStrategy<
  TData = Awaited<ReturnType<typeof getStrategy>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getStrategy>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetStrategy<
  TData = Awaited<ReturnType<typeof getStrategy>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getStrategy>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetStrategyQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getMatchAnalysis = (id: string, signal?: AbortSignal) => {
  return customInstance<MatchAnalysisItem>({
    url: `https://api.football-genie.com/o1-typescript-service/analysis/${id}`,
    method: "GET",
    signal,
  });
};

export const getGetMatchAnalysisQueryKey = (id: string) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/analysis/${id}`,
  ] as const;
};

export const getGetMatchAnalysisQueryOptions = <
  TData = Awaited<ReturnType<typeof getMatchAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMatchAnalysis>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMatchAnalysisQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMatchAnalysis>>
  > = ({ signal }) => getMatchAnalysis(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMatchAnalysis>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMatchAnalysisQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMatchAnalysis>>
>;
export type GetMatchAnalysisQueryError = ErrorType<unknown>;

export function useGetMatchAnalysis<
  TData = Awaited<ReturnType<typeof getMatchAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMatchAnalysis>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMatchAnalysis>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMatchAnalysis<
  TData = Awaited<ReturnType<typeof getMatchAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMatchAnalysis>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMatchAnalysis>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetMatchAnalysis<
  TData = Awaited<ReturnType<typeof getMatchAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMatchAnalysis>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetMatchAnalysis<
  TData = Awaited<ReturnType<typeof getMatchAnalysis>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getMatchAnalysis>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetMatchAnalysisQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const unlock = (id: string) => {
  return customInstance<UnlockMatchResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/free-tier/unlock/${id}`,
    method: "POST",
  });
};

export const getUnlockMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unlock>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof unlock>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof unlock>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return unlock(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnlockMutationResult = NonNullable<
  Awaited<ReturnType<typeof unlock>>
>;

export type UnlockMutationError = ErrorType<unknown>;

export const useUnlock = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unlock>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof unlock>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getUnlockMutationOptions(options);

  return useMutation(mutationOptions);
};

export const balance = (signal?: AbortSignal) => {
  return customInstance<FreeAnalysisBalanceResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/free-tier/balance`,
    method: "GET",
    signal,
  });
};

export const getBalanceQueryKey = () => {
  return [
    `https://api.football-genie.com/o1-typescript-service/free-tier/balance`,
  ] as const;
};

export const getBalanceQueryOptions = <
  TData = Awaited<ReturnType<typeof balance>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof balance>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBalanceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof balance>>> = ({
    signal,
  }) => balance(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof balance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BalanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof balance>>
>;
export type BalanceQueryError = ErrorType<unknown>;

export function useBalance<
  TData = Awaited<ReturnType<typeof balance>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof balance>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof balance>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useBalance<
  TData = Awaited<ReturnType<typeof balance>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof balance>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof balance>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useBalance<
  TData = Awaited<ReturnType<typeof balance>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof balance>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useBalance<
  TData = Awaited<ReturnType<typeof balance>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof balance>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getBalanceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getFixture = (id: string, signal?: AbortSignal) => {
  return customInstance<FixtureRecord>({
    url: `https://api.football-genie.com/o1-typescript-service/fixtures/${id}`,
    method: "GET",
    signal,
  });
};

export const getGetFixtureQueryKey = (id: string) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/fixtures/${id}`,
  ] as const;
};

export const getGetFixtureQueryOptions = <
  TData = Awaited<ReturnType<typeof getFixture>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixture>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFixtureQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixture>>> = ({
    signal,
  }) => getFixture(id, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFixture>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFixtureQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFixture>>
>;
export type GetFixtureQueryError = ErrorType<unknown>;

export function useGetFixture<
  TData = Awaited<ReturnType<typeof getFixture>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixture>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFixture>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFixture<
  TData = Awaited<ReturnType<typeof getFixture>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixture>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFixture>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFixture<
  TData = Awaited<ReturnType<typeof getFixture>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixture>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetFixture<
  TData = Awaited<ReturnType<typeof getFixture>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixture>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetFixtureQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getFixtures = (
  params: GetFixturesParams,
  signal?: AbortSignal,
) => {
  return customInstance<FixtureRecord[]>({
    url: `https://api.football-genie.com/o1-typescript-service/fixtures`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetFixturesQueryKey = (params: GetFixturesParams) => {
  return [
    `https://api.football-genie.com/o1-typescript-service/fixtures`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetFixturesQueryOptions = <
  TData = Awaited<ReturnType<typeof getFixtures>>,
  TError = ErrorType<unknown>,
>(
  params: GetFixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixtures>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFixturesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFixtures>>> = ({
    signal,
  }) => getFixtures(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFixtures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFixturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFixtures>>
>;
export type GetFixturesQueryError = ErrorType<unknown>;

export function useGetFixtures<
  TData = Awaited<ReturnType<typeof getFixtures>>,
  TError = ErrorType<unknown>,
>(
  params: GetFixturesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixtures>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFixtures>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFixtures<
  TData = Awaited<ReturnType<typeof getFixtures>>,
  TError = ErrorType<unknown>,
>(
  params: GetFixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixtures>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFixtures>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFixtures<
  TData = Awaited<ReturnType<typeof getFixtures>>,
  TError = ErrorType<unknown>,
>(
  params: GetFixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixtures>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetFixtures<
  TData = Awaited<ReturnType<typeof getFixtures>>,
  TError = ErrorType<unknown>,
>(
  params: GetFixturesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getFixtures>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetFixturesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const contact = (contactBody: ContactBody) => {
  return customInstance<ContactResponse>({
    url: `https://api.football-genie.com/o1-typescript-service/communications/contact`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: contactBody,
  });
};

export const getContactMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof contact>>,
    TError,
    { data: ContactBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof contact>>,
  TError,
  { data: ContactBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof contact>>,
    { data: ContactBody }
  > = (props) => {
    const { data } = props ?? {};

    return contact(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ContactMutationResult = NonNullable<
  Awaited<ReturnType<typeof contact>>
>;
export type ContactMutationBody = ContactBody;
export type ContactMutationError = ErrorType<unknown>;

export const useContact = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof contact>>,
    TError,
    { data: ContactBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof contact>>,
  TError,
  { data: ContactBody },
  TContext
> => {
  const mutationOptions = getContactMutationOptions(options);

  return useMutation(mutationOptions);
};
