import React from "react";

import classNames from "classnames";

import styles from "./BillingCycleToggle.module.scss";

type BillingCycle = "week" | "month";

interface BillingCycleToggleProps {
  value?: BillingCycle;
  onChange: (cycle: BillingCycle) => void;
}

const BillingCycleToggle: React.FC<BillingCycleToggleProps> = ({
  value = "week",
  onChange,
}) => {
  const toggleBillingCycle = () => {
    const newCycle: BillingCycle = value === "week" ? "month" : "week";
    onChange(newCycle);
    if (onChange) {
      onChange(newCycle);
    }
  };

  const handleSetWeekly = () => {
    if (value !== "week") {
      onChange("week");
      if (onChange) {
        onChange("week");
      }
    }
  };

  const handleSetMonthly = () => {
    if (value !== "month") {
      onChange("month");
      if (onChange) {
        onChange("month");
      }
    }
  };

  return (
    <div
      className={styles.container}
      role="group"
      aria-label="Billing Cycle Toggle"
      data-aos="fade-up" // Ensure AOS is integrated if using animations
    >
      <button
        type="button"
        className={classNames(styles.button, {
          [styles.active]: value === "week",
          [styles.inactive]: value !== "week",
        })}
        onClick={handleSetWeekly}
        aria-pressed={value === "week"}
      >
        Weekly
      </button>

      {/* Toggle Switch */}
      <label htmlFor="billing-toggle" className={styles.toggleLabel}>
        <input
          type="checkbox"
          id="billing-toggle"
          className={styles.toggleInput}
          checked={value === "month"}
          onChange={toggleBillingCycle}
          aria-label="Toggle Billing Cycle"
        />
        <div className={styles.toggleSwitch}></div>
      </label>

      <button
        type="button"
        className={classNames(styles.button, {
          [styles.active]: value === "month",
          [styles.inactive]: value !== "month",
        })}
        onClick={handleSetMonthly}
        aria-pressed={value === "month"}
      >
        Monthly
      </button>
    </div>
  );
};

export default BillingCycleToggle;
