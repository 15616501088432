import React, { FC, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useForm, useWatch } from "react-hook-form";

import {
  FixtureItem,
  getGetUserTipQueryKey,
  getGetUserTipsSummaryQueryKey,
  Tip,
  useCreateUserTip,
  useGetUserTip,
} from "@apiv2/o1-typescript-service";

import { formatProbability, roundNumber } from "@utils";

import {
  Modal,
  TeamsHeader,
  TeamsInfoItem,
  useTeamsHeaderProps,
} from "@ui/components";
import { Button, Icon, Text, Notification } from "@ui/elements";
import { Stack } from "@ui/layout";

import {
  DeleteTipModal,
  toaster,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components";

import "./TipSave.scss";
import { usePageSettings } from "../../../PageTemplate/PageTemplate";

import { oddsToNumber, TipSaveForm, TipSaveFormFields } from "./TipSaveForm";

type TipSaveProps = {
  fixture: FixtureItem;
  tip: Tip;
};

export const TipSave: FC<TipSaveProps> = ({ tip, fixture }) => {
  const teamsHeaderProps = useTeamsHeaderProps(fixture);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { data: userTipData, isPending: isUserTipPending } = useGetUserTip(
    tip.id,
    {
      query: {
        queryKey: [getGetUserTipQueryKey(tip.id)],
      },
    },
  );

  const {
    mutateAsync: createTipMutate,
    error: createTipError,
    isPending: createTipLoading,
  } = useCreateUserTip();
  const queryClient = useQueryClient();
  const { bankroll, focusBankroll, hasSubscription } = usePageSettings();
  const hasTipSaved = Object.keys(userTipData || {}).length > 0;

  const defaultValues = {
    odds: oddsToNumber(hasTipSaved ? userTipData?.odds : tip.odds),
    stake: hasTipSaved
      ? Number(userTipData?.bet_amount)
      : roundNumber((Number(bankroll) * Number(tip.bet_amount)) / 100),
  };

  const form = useForm<TipSaveFormFields>();

  const currentValues = useWatch({ control: form.control });

  const handleSaveButtonClick = async () => {
    if (!bankroll) {
      return focusBankroll?.();
    }

    setModalOpen(true);
    resetForm();
  };

  const resetForm = () => {
    form.reset(defaultValues);
  };

  const onSubmit = async (values: TipSaveFormFields) => {
    try {
      await createTipMutate({
        data: {
          bet_amount: Number(values.stake),
          odds: oddsToNumber(values.odds),
          id: tip.id,
        },
      });

      queryClient.invalidateQueries({
        queryKey: [getGetUserTipQueryKey(tip.id)],
      });
      queryClient.invalidateQueries({
        queryKey: [getGetUserTipsSummaryQueryKey()],
      });

      toaster({
        title: "Successful update!",
        message: "We've updated your portfolio. Good luck!",
      });

      setModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const error = createTipError?.response?.data;

  if (!hasSubscription) {
    return (
      <Tooltip placement="bottom-end">
        <TooltipTrigger>
          <Stack>
            <Button variant="light" disabled>
              <Icon icon="BookmarkSimple" />
            </Button>
          </Stack>
        </TooltipTrigger>
        <TooltipContent>
          <div className="tooltip">
            <Stack direction="column">
              <Text>
                Saving tip is only available to subscribed users. Subscribe now
                to save your tips.
              </Text>
            </Stack>
          </div>
        </TooltipContent>
      </Tooltip>
    );
  }

  return (
    <>
      <Button
        variant="light"
        color="info"
        onClick={handleSaveButtonClick}
        isLoading={isUserTipPending}
      >
        {hasTipSaved && <Icon icon="PencilSimple" weight="duotone" />}
        {!hasTipSaved && <Icon icon="BookmarkSimple" />}
      </Button>
      <DeleteTipModal
        open={deleteModalOpen}
        onClose={(closeAll) => {
          setDeleteModalOpen(false);
          if (closeAll) {
            setModalOpen(false);
          }
        }}
        id={tip.id}
      />
      <Modal
        header={
          <TeamsHeader
            title={
              <Stack align="center">
                <Text>
                  {tip.category} {tip.name}
                </Text>{" "}
                <Text variant="secondary">
                  {formatProbability(tip.probability)}
                </Text>
              </Stack>
            }
            subtitle={
              <TeamsInfoItem label={teamsHeaderProps.title as string} />
            }
            homeLogo={teamsHeaderProps.homeLogo}
            awayLogo={teamsHeaderProps.awayLogo}
          />
        }
        footer={
          <Stack isFullwidth align="center">
            <Stack isFullwidth>
              {hasTipSaved && (
                <Button
                  variant="light"
                  color="danger"
                  type="button"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  Remove
                </Button>
              )}
              {JSON.stringify(currentValues) !==
                JSON.stringify(defaultValues) && (
                <Button
                  variant="text"
                  type="button"
                  onClick={() => resetForm()}
                >
                  Reset to default
                </Button>
              )}
            </Stack>
            <Stack>
              <Button
                variant="white"
                type="button"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="light"
                color="success"
                type="submit"
                onClick={() => form.handleSubmit(onSubmit)()}
                isLoading={createTipLoading}
              >
                {hasTipSaved ? "Update" : "Save"}
              </Button>
            </Stack>
          </Stack>
        }
        isActive={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Stack direction="column" gap="xl">
          {error && (
            <Notification color="danger">
              <Stack direction="column" gap="xxs">
                <Text weight="bold">{error.error}</Text>
                <Text>{error.message}</Text>
              </Stack>
            </Notification>
          )}
          <TipSaveForm tip={tip} form={form} />
        </Stack>
      </Modal>
    </>
  );
};
