import React, { useEffect } from "react";

import { fetchAuthSession } from "@aws-amplify/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Icon, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./SuccessSubscription.scss";
import { getUtmParams } from "../../utils/getUtmParams";

export const SuccessSubscription = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    fetchAuthSession().then(console.log);
    const price = searchParams.get("price") || 14.9;
    gtag("event", "purchase", {
      currency: "EUR",
      value: Number(price),
      ...getUtmParams(),
    });
    gtag("event", "conversion", {
      send_to: "AW-11503334227/FjqoCO6s_4EaENPmm-0q",
      value: Number(price),
      currency: "EUR",
    });
    window.fbq("track", "Subscribe", {
      currency: "EUR",
      value: Number(price),
      ...getUtmParams(),
    });
  }, []);

  return (
    <Stack direction="column" className="success-subscription-box">
      <Stack direction="column" align="center" gap="lg">
        <Stack direction="column" gap="xs" justify="center" align="center">
          <Icon
            icon="CheckCircle"
            size="large"
            weight="fill"
            className="has-text-success"
          />
          <Title size={4}>Subscription Successful</Title>
        </Stack>
        <Text className="has-text-centered">
          Welcome to the Football Genie Team! You can now start taking advantage
          of our AI Tips. We wish you the best of luck and many profits!
        </Text>
        <Stack direction="column" gap="xs">
          <Button
            color="primary"
            type="button"
            onClick={() => navigate("/upcoming-tips")}
          >
            Explore Upcoming Tips
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
