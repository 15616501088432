import React, { FC, PropsWithChildren, useEffect, useState } from "react";

import { fetchUserAttributes } from "aws-amplify/auth";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { withController } from "@utils";

import { useCheckEmail } from "@api/cognito/checkEmail";
import { useValidateRecaptcha } from "@api/cognito/validateRecaptcha";

import { Button, Divider, Notification, Link } from "@ui/elements";
import { Input } from "@ui/forms";
import { Stack } from "@ui/layout";

import { useAuth } from "@components";

import "./login.scss";

import { SocialLogin } from "../../components/SocialLogin/SocialLogin";

type VisibilityToggleBoxProps = PropsWithChildren<{
  openHeight?: string;
  minusSpacing?: string;
  isActive?: boolean;
}>;

const VisibilityToggleBox: FC<VisibilityToggleBoxProps> = ({
  children,
  openHeight,
  isActive,
  minusSpacing,
}) => {
  return (
    <div
      className="visibility-toggle-box"
      style={{
        maxHeight: isActive ? openHeight : 0,
        marginTop: !isActive ? `calc(-1 * (${minusSpacing} / 2))` : 0,
      }}
    >
      {children}
    </div>
  );
};

type LoginFields = {
  email: string;
  password: string;
  family_name: string;
  given_name: string;
  password_confirm: string;
};

const ControlledInput = withController<LoginFields>()(Input);

export const Login = () => {
  const [searchParams] = useSearchParams();
  const { handleSubmit, control, watch, reset } = useForm<LoginFields>({
    defaultValues: {
      email: searchParams.get("email") || "",
    },
  });
  const [loginActive, setLoginActive] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync: validateRecaptcha, isLoading: recaptchaLoading } =
    useValidateRecaptcha();

  const email = watch("email");
  const {
    refetch: checkEmail,
    isLoading: checkEmailLoading,
    isRefetching: checkEmailRefetching,
  } = useCheckEmail({ email }, { enabled: false });
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authContext = useAuth();

  useEffect(() => {
    if (authContext.isAuthenticated === true) {
      navigate(searchParams.get("redirect_to") || "/");
    }
  }, [authContext.isAuthenticated]);

  const onSubmit = async (values: LoginFields) => {
    setError("");
    setLoading(true);

    try {
      const userAgent = navigator.userAgent;
      const isInstagramBrowser = userAgent.includes("Instagram");

      if (!isInstagramBrowser) {
        if (!executeRecaptcha) {
          console.log("Execute recaptcha not yet available");
          setError("Execute recaptcha not yet available");
          return;
        }

        const token = await executeRecaptcha("login");
        const recaptchaResponse = await validateRecaptcha({ token });

        if (!recaptchaResponse.success) {
          setError("Failed to validate reCAPTCHA. Please try again.");
          return;
        }
      }

      const { data, error } = await checkEmail();

      if (error) {
        setError(error?.response?.data.error);
        return reset({ email: "" });
      }

      // Only Email input is visible
      if (!loginActive) {
        if (!data?.hasUser) {
          navigate(`/sign-up?email=${values.email}`);
        } else {
          setLoginActive(true);
        }
        return false;
      }

      // Let user log in
      if (data?.hasUser && data.given_name && data.family_name && data.name) {
        const { result, error } = await authContext.login(
          values.email,
          values.password,
        );

        if (typeof result !== "boolean") {
          if (result.isSignedIn) {
            const attributes = await fetchUserAttributes();
            toast(
              <p>
                <strong>Welcome {attributes.given_name}!</strong>
                <br />
                Good luck for your betting journey! 🍀
              </p>,
            );
          }
        }

        if (error) {
          setError(error.message);
        }

        return false;
      }

      setError("");
    } catch (err) {
      console.error(err);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const isLoading =
    checkEmailRefetching || checkEmailLoading || loading || recaptchaLoading;

  const errorMessage = error || searchParams.get("error_description");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>Football Genie - Login</title>
      </Helmet>
      <Stack direction="column" gap="xxl">
        <Stack direction="column" gap="xl">
          <Stack direction="column">
            <ControlledInput
              placeholder="E-mail"
              type="email"
              name="email"
              control={control}
              rules={{
                required: "E-mail address is a required field",
              }}
              autoComplete="username"
            />
            <VisibilityToggleBox
              openHeight="4rem"
              isActive={loginActive}
              minusSpacing="var(--spacing-xl)"
            >
              <ControlledInput
                placeholder="Password"
                type="password"
                name="password"
                control={control}
                autoComplete="current-password"
              />
              <Link to={`/forgot-password?email=${email}`} size="small">
                Forgot my password
              </Link>
            </VisibilityToggleBox>
            {errorMessage && (
              <Notification color="danger">{errorMessage}</Notification>
            )}

            <Stack direction="column" gap="xs">
              <Button
                type="submit"
                isLoading={isLoading}
                color="primary"
                isFullwidth
              >
                {loginActive && "Log In"}
                {!loginActive && "Continue"}
              </Button>
              <VisibilityToggleBox
                openHeight="2.5rem"
                isActive={loginActive}
                minusSpacing="var(--spacing-xs)"
              >
                <Link to={`/sign-up?email=${email}`}>
                  <Button variant="text" isFullwidth>
                    Create Account
                  </Button>
                </Link>
              </VisibilityToggleBox>
            </Stack>
          </Stack>

          <Divider text="or" />

          <SocialLogin />
        </Stack>
      </Stack>
    </form>
  );
};
