import React, {
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";

import classNames from "classnames";
import ReactDOM from "react-dom";

import { Button, Icon, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./Modal.scss";

type ModalProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    isActive: boolean;
    onClose: () => void;
    header?: string | ReactNode;
    footer?: ReactNode;
    size?: "normal" | "large";
    disableClose?: boolean;
  }>;
export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      isActive,
      onClose,
      children,
      header,
      footer,
      size,
      disableClose,
      className,
      ...rest
    },
    ref,
  ) => {
    const [shouldRender, setShouldRender] = useState(isActive);
    const [isVisible, setIsVisible] = useState(isActive);

    useEffect(() => {
      if (isActive) {
        setShouldRender(true);
        document.body.classList.add("is-clipped");
        setTimeout(() => setIsVisible(true), 10);
      } else {
        setIsVisible(false);
        document.body.classList.remove("is-clipped");
        const timeoutId = setTimeout(() => setShouldRender(false), 300); // Delay matches the CSS transition duration
        return () => clearTimeout(timeoutId);
      }

      return () => {
        document.body.classList.remove("is-clipped");
      };
    }, [isActive]);

    if (!shouldRender) return null;

    return ReactDOM.createPortal(
      <div
        ref={ref}
        className={classNames(
          "modal",
          {
            "is-active": isVisible,
            [`is-${size}`]: size,
          },
          className,
        )}
        {...rest}
      >
        <div
          className="modal-background"
          onClick={() => !disableClose && onClose()}
        ></div>
        <div className="modal-card">
          {header && (
            <header className="modal-card-head">
              <Stack isFullwidth justify="between" align="center">
                {typeof header === "string" ? (
                  <Title size={4}>{header}</Title>
                ) : (
                  header
                )}
                {!disableClose && (
                  <Stack align="center">
                    <Button variant="white" isRounded onClick={onClose}>
                      <Icon icon="X" />
                    </Button>
                  </Stack>
                )}
              </Stack>
            </header>
          )}
          <section className="modal-card-body">{children}</section>
          {footer && <footer className="modal-card-foot">{footer}</footer>}
        </div>
      </div>,
      document.body,
    );
  },
);

Modal.displayName = "Modal";
