import React, { createContext, FC, useContext } from "react";

import dayjs from "dayjs";

import { useIsAdmin } from "@utils";

import { FixtureItem } from "@api/football/types/fixture";

import {
  Drawer,
  TabItem,
  TabList,
  TabPanel,
  Tabs,
  TeamsHeader,
  TeamsInfoItem,
} from "@ui/components";
import { Button, Icon } from "@ui/elements";
import { Stack } from "@ui/layout";

import { AdminActions } from "./Tabs/AdminActions";
import { KeyStatisticsTab } from "./Tabs/KeyStatistics";
import { MatchAnalysis } from "./Tabs/MatchAnalysis";
import "./MatchDetailsDrawer.scss";
import { OddsTab } from "./Tabs/Odds";

type MatchDetailsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  fixture: FixtureItem;
};

type MatchDetailsContextType = {
  fixture: FixtureItem;
  isOpen: boolean;
};

const MatchDetailsContext = createContext<MatchDetailsContextType | undefined>(
  undefined,
);

export const useMatchDetails = () => {
  const context = useContext(MatchDetailsContext);
  if (!context) {
    throw new Error(
      "useMatchDetails must be used within a MatchDetails provider",
    );
  }
  return context;
};

export const MatchDetailsDrawer: FC<MatchDetailsDrawerProps> = ({
  isOpen,
  onClose,
  fixture,
}) => {
  const isAdmin = useIsAdmin();

  return (
    <MatchDetailsContext.Provider value={{ fixture, isOpen }}>
      <Tabs active="match-analysis">
        <Drawer
          isActive={isOpen}
          onClose={onClose}
          header={
            <Stack direction="column" gap="sm">
              <Stack justify="between" className="minWNone">
                <Stack className="match-details-teams-header-wrapper">
                  <TeamsHeader
                    id={fixture.fixture.id}
                    homeLogo={fixture.teams.home.logo}
                    awayLogo={fixture.teams.away.logo}
                    title={`${fixture.teams.home.name} vs ${fixture.teams.away.name}`}
                    subtitle={
                      <>
                        <TeamsInfoItem
                          icon="Alarm"
                          label={dayjs(fixture.fixture.date).format("HH:mm")}
                        />
                        <TeamsInfoItem
                          icon="Trophy"
                          label={fixture.league.name}
                        />
                        <TeamsInfoItem
                          icon="FlagCheckered"
                          label={fixture.fixture.status.long}
                        />
                      </>
                    }
                  />
                </Stack>
                <Stack align="center">
                  <Button variant="white" isRounded onClick={onClose}>
                    <Icon icon="X" />
                  </Button>
                </Stack>
              </Stack>
              <TabList className="match-details-drawer__tab-list">
                <TabItem tab="match-analysis">
                  <Icon icon="MagicWand" />
                  <span>AI Analysis</span>
                </TabItem>
                <TabItem tab="key-statistics">
                  <Icon icon="ChartBar" />
                  <span>Statistics</span>
                </TabItem>
                <TabItem tab="odds">
                  <Icon icon="Scales" />
                  <span>Odds</span>
                </TabItem>
                {isAdmin.isInGroup && (
                  <TabItem tab="admin">
                    <Icon icon="LockSimple" />
                    <span>Admin</span>
                  </TabItem>
                )}
              </TabList>
            </Stack>
          }
        >
          <Stack direction="column" gap="xxl">
            <TabPanel tab="match-analysis">
              <MatchAnalysis />
            </TabPanel>
            <TabPanel tab="key-statistics">
              <KeyStatisticsTab />
            </TabPanel>
            <TabPanel tab="odds">
              <OddsTab />
            </TabPanel>
            {isAdmin.isInGroup && (
              <TabPanel tab="admin">
                <AdminActions />
              </TabPanel>
            )}
          </Stack>
        </Drawer>
      </Tabs>
    </MatchDetailsContext.Provider>
  );
};
