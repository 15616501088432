import React, { FC } from "react";

import { Modal } from "@ui/components";
import { Box, Button, Icon, Text, Title } from "@ui/elements";
import { Cell, Grid, Stack } from "@ui/layout";

import { CommonModalProps } from "@components";

export const TipTypeInfoModal: FC<CommonModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal
      isActive={isOpen}
      onClose={() => onClose()}
      header={
        <Stack align="center">
          <Icon icon="Info" />
          <Title size={4}>Tip Types</Title>
        </Stack>
      }
      style={{ zIndex: 100 }}
      footer={
        <Stack justify="end" isFullwidth>
          <Button variant="light" onClick={() => onClose()}>
            Understood
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" gap="xl">
        <Stack direction="column" gap="xl">
          <Title size={6}>
            Understanding Tip Types: Value-Based vs. Result-Oriented
          </Title>
          <Text>
            At Football Genie, we provide two distinct approaches to betting
            tips, each tailored to suit different strategies and goals.
            Here&#39;s a breakdown:
          </Text>
        </Stack>

        <Grid minColWidth={11}>
          <Cell>
            <Box>
              <Stack direction="column" gap="lg">
                <Title size={6}>
                  <Stack align="center" gap="xs">
                    <Icon icon="Target" />
                    <span>Result-Oriented Tips</span>
                  </Stack>
                </Title>
                <Text>
                  Ideal for bettors seeking straightforward result predictions,
                  with extensive options to choose from.
                </Text>
                <Stack direction="column">
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Focuses on predicting the most likely outcomes with less
                    regard of the odds.
                  </Stack>
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Over 25+ markets to choose from
                  </Stack>
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Probabilities are less accurate
                  </Stack>
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Provides extensive betting options
                  </Stack>
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Short tip reasoning
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Cell>
          <Cell>
            <Box>
              <Stack direction="column" gap="lg">
                <Title size={6}>
                  <Stack align="center" gap="xs">
                    <Icon icon="ChartBar" />
                    <span>Value-Based Tips</span>
                  </Stack>
                </Title>
                <Text>
                  Ideal for bettors aiming to maximize long-term profitability
                  by exposing edge over bookmaker.
                </Text>
                <Stack direction="column">
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Focuses on strategic betting with an edge over the odds.
                  </Stack>
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Only 3-5 markets to choose from
                  </Stack>
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Probabilities are aimed to be more accurate
                  </Stack>
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Provides very few betting options
                  </Stack>
                  <Stack gap="sm">
                    <Icon icon="CheckCircle" />
                    Tip reasoning with risk analysis
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Cell>
        </Grid>
      </Stack>
    </Modal>
  );
};
