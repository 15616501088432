import "./FreePlanBalanceIndicator.scss";
import classNames from "classnames";

import { useBalance } from "@apiv2/o1-typescript-service";

import { Button, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

import { useModal } from "@components";

import { usePageSettings } from "../PageTemplate/PageTemplate";

export const FreePlanBalanceIndicator = () => {
  const modals = useModal();
  const pageSettings = usePageSettings();
  const {
    data: balanceData,
    isPending: balancePending,
    isLoading: balanceLoading,
  } = useBalance();

  if (
    !balanceData ||
    pageSettings.hasSubscription ||
    balancePending ||
    balanceLoading ||
    pageSettings.isLoading
  ) {
    return null;
  }

  const width =
    (balanceData.unlocked_analysis / balanceData.max_free_analysis) * 100;

  return (
    <div
      className={classNames("free-plan-indicator-wrapper", {
        "is-danger": balanceData?.unlocked_analysis === 3,
        "is-warning": balanceData?.unlocked_analysis === 2,
        "is-success": balanceData?.unlocked_analysis < 2,
      })}
    >
      <div className="free-plan-indicator">
        <Stack isFullwidth justify="between">
          <Text weight="bold">Free plan balance</Text>
          <div className="flex items-center">
            <span className="text-lg font-bold">
              {balanceData?.unlocked_analysis}
            </span>
            <span className="text-sm text-gray-500 ml-1">
              /{balanceData?.max_free_analysis}
            </span>
          </div>
        </Stack>
        <div className="free-plan-indicator__progress">
          <div
            className="free-plan-indicator__progress__inner"
            style={{ width: `${width}%` }}
          />
        </div>
      </div>
      <div>
        <Button variant="white" onClick={() => modals.open("subscription")}>
          Upgrade
        </Button>
      </div>
    </div>
  );
};
