import React from "react";

import { CellContext, ColumnDefTemplate } from "@tanstack/react-table";

import { Tip } from "@apiv2/o1-typescript-service";
import { useGetTipAnalysis } from "@apiv2/o1-typescript-service";

import { capitalize, formatProbability } from "@utils";

import { Dropdown, InfoBox } from "@ui/components";
import { Button, Content, Icon, StatusBadge, Tag, Text } from "@ui/elements";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import { ColorMap } from "@components";

import { FAQItem } from "../../../FAQ/FAQ";

export const ReasoningColumn: ColumnDefTemplate<CellContext<Tip, string>> = (
  info,
) => {
  const item = info.row.original;
  const { data: tipAnalysis, isLoading: tipAnalysisLoading } =
    useGetTipAnalysis(item.id, {
      query: { enabled: info.column.columnDef.meta?.tipType === "value" },
    });

  return (
    <Dropdown
      placement="bottom-end"
      trigger={
        <Button variant="light">
          <Icon icon="Info" />
        </Button>
      }
    >
      <Stack className="reasoning-dropdown-content" direction="column" gap="sm">
        <Stack align="center" justify="between">
          <Stack align="center" gap="xs">
            <StatusBadge type={ColorMap[item.rating]}></StatusBadge>
            <Stack direction="column" gap="none">
              <Text weight="bold" isNarrow>
                {capitalize(item.rating)}
              </Text>
              <Text variant="secondary" size="small" isNarrow>
                Tip Rating
              </Text>
            </Stack>
          </Stack>
          <Tag size="medium">{formatProbability(item.probability)}</Tag>
        </Stack>
        {info.column.columnDef.meta?.tipType === "value" && (
          <>
            {tipAnalysisLoading && (
              <div className="skeleton-lines">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}

            {!tipAnalysisLoading && !tipAnalysis && (
              <InfoBox title="Ooops ...">
                Seems like this tip has no AI reasoning.
                <br />
                Please try again later.
              </InfoBox>
            )}
            {tipAnalysis && (
              <Stack direction="column" gap="xl">
                <Content noMargin>
                  <p>{tipAnalysis?.reasoning}</p>
                </Content>
                {!!tipAnalysis?.risks.length && (
                  <FAQItem icon="Warning" title="Potential Risks">
                    <Stack direction="column" gap="lg">
                      {tipAnalysis?.risks.map((risk) => {
                        const badgeType: Colors = (() => {
                          switch (risk.level) {
                            case "Moderate":
                              return "warning";
                            case "Low":
                              return "success";
                            case "High":
                              return "danger";
                          }
                        })();

                        return (
                          <Stack
                            direction="column"
                            key={`risk-item-${risk.title}`}
                            gap="xs"
                          >
                            <Stack align="center">
                              <StatusBadge type={badgeType} />
                              <Stack
                                direction="column"
                                gap="none"
                                align="start"
                              >
                                <Text isNarrow weight="bold" variant="primary">
                                  {risk.title}
                                </Text>
                                <Text size="small" variant="secondary">
                                  {risk.level} risk
                                </Text>
                              </Stack>
                            </Stack>
                            <Text variant="secondary" weight="light">
                              {risk.reasoning}
                            </Text>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </FAQItem>
                )}
              </Stack>
            )}
          </>
        )}
        {info.column.columnDef.meta?.tipType === "resultOriented" && (
          <Text>{info.getValue()}</Text>
        )}
      </Stack>
    </Dropdown>
  );
};
