export const getUtmParams = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const utm_source =
    queryParams.get("utm_source") ||
    localStorage.getItem("utm_source") ||
    "unknown";

  const utm_medium =
    queryParams.get("utm_medium") ||
    localStorage.getItem("utm_medium") ||
    "unknown";

  const utm_campaign =
    queryParams.get("utm_campaign") ||
    localStorage.getItem("utm_campaign") ||
    "unknown";

  if (utm_source !== "unknown") {
    localStorage.setItem("utm_source", utm_source);
  }
  if (utm_medium !== "unknown") {
    localStorage.setItem("utm_medium", utm_medium);
  }
  if (utm_campaign !== "unknown") {
    localStorage.setItem("utm_campaign", utm_campaign);
  }

  return {
    utm_source,
    utm_medium,
    utm_campaign,
  };
};
