import React, { useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

import {
  useGetTipsSummary,
  useGetTipsPerformance,
  useInitialFiltersGet,
  GetTipsMatchesQueryParams,
  PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsType,
  GetTipsParams,
  TipType,
} from "@apiv2/o1-typescript-service";

import {
  InfoBox,
  Pagination,
  TabItem,
  TabList,
  Tabs,
  TitleBlock,
} from "@ui/components";
import { Icon } from "@ui/elements";
import { Column, Columns, Stack } from "@ui/layout";

import {
  MultiFilter,
  Filter,
  TipCardSkeleton,
  TipsTable,
  useModal,
} from "@components";

import { TipBox } from "../upcoming-tips/ResultOrientedTips/ResultOrientedTips";

import { AIPerformanceCards } from "./AIPerformanceSummaryCards";
import { DatePickerBox, DatePickerBoxFormValues } from "./DatePickerBox";
import { TopPerformers } from "./TopPerformers";

import "./AIPerformance.scss";

dayjs.extend(isoWeek);

export const initialFilters: Filter[] = [
  {
    id: "ratings",
    name: "Tip Rating",
    icon: "Star",
    type: "multi-select-colored",
    value: [],
    enabled: false,
  },
  {
    id: "leagues",
    name: "League",
    icon: "Flag",
    type: "multi-select-image",
    value: [],
    enabled: false,
    enableSearch: true,
  },
  {
    id: "categories",
    name: "Tip Category",
    icon: "Tag",
    type: "multi-select",
    value: [],
    enabled: false,
    enableSearch: true,
  },
  {
    id: "odds",
    name: "Odds",
    icon: "Scales",
    type: "range",
    value: { min: 1, max: 5 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 1,
      max: 20,
      step: 0.1,
    },
  },
  {
    id: "edge",
    name: "Edge",
    icon: "TrendUp",
    type: "range",
    formatValue: (value) => `${value}%`,
    value: { min: -50, max: 50 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: -100,
      max: 100,
      step: 1,
    },
  },
  {
    id: "stake",
    name: "Stake",
    icon: "Coins",
    type: "range",
    formatValue: (value) => `${value}%`,
    value: { min: 0, max: 50 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 100,
      step: 1,
    },
  },
  {
    id: "probability",
    name: "Probability",
    icon: "Percent",
    type: "range",
    formatValue: (value) => `${value}%`,
    value: { min: 1, max: 100 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 100,
      step: 1,
    },
  },
  {
    id: "predictability",
    name: "Predictability",
    icon: "Eye",
    type: "range",
    value: { min: 1, max: 10 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 10,
      step: 1,
    },
  },
  {
    id: "best_choice",
    name: "Best Choice",
    icon: "SealCheck",
    type: "switch",
    value: true,
    enabled: false,
  },
  {
    id: "safe_to_bet",
    name: "Safe for Betting",
    icon: "ShieldCheck",
    type: "switch",
    value: true,
    enabled: false,
  },
  {
    id: "match_importance",
    name: "Match Importance",
    icon: "Star",
    type: "range",
    value: { min: 1, max: 10 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 10,
      step: 1,
    },
  },
];

export const reconstructFilters = (
  data: PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsType,
): Filter[] => {
  return [...initialFilters].map((filter) => {
    let enabled = false;
    let value = filter.value as never;

    if (
      filter.type === "multi-select" ||
      filter.type === "multi-select-image" ||
      filter.type === "multi-select-colored"
    ) {
      const key =
        `include_${filter.id}` as keyof PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsType;
      if (key in data) {
        enabled = true;
        value = (data[key] as string[])?.map((e) => String(e)) as never;
      }
    } else if (filter.type === "range") {
      const min_key =
        `min_${filter.id}` as keyof PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsType;
      const max_key =
        `max_${filter.id}` as keyof PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsType;

      if (min_key in data || max_key in data) {
        enabled = true;

        value = {
          min: data[min_key] || filter.value.min,
          max: data[max_key] || filter.value.max,
        } as never;
      }
    } else if (filter.type === "switch") {
      if (filter.id in data) {
        enabled = true;
        value = data[
          filter.id as keyof PickGetTipsQueryParamsExcludeKeyofGetTipsQueryParamsType
        ] as never;
      }
    }

    return {
      ...filter,
      enabled,
      value,
    };
  });
};

export const AIPerformance = () => {
  const [filters, setFilters] = useState<Filter[]>(initialFilters);
  const modal = useModal();
  const [date, setDate] = useState<DatePickerBoxFormValues>({
    timePeriod: "last-7-days",
    from: dayjs().subtract(7, "days").add(1, "day").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });
  const [activeTab, setActiveTab] = useState<TipType>("resultOriented");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const filtersModified = useRef(false);
  const {
    data: initialFiltersData,
    refetch: refetchInitialFilters,
    isLoading: initialFiltersLoading,
    isRefetching: initialFiltersRefetching,
  } = useInitialFiltersGet(
    {
      from: date.from,
      to: date.to,
      type: activeTab,
    },
    {
      query: {
        enabled: !filtersModified.current,
      },
    },
  );

  const [tipsParams, setTipsParams] = useState<Partial<GetTipsParams>>({});

  const { data: summaryData, isLoading: summaryLoading } = useGetTipsSummary(
    {
      ...tipsParams,
      from: date.from,
      to: date.to,
      type: activeTab,
    },
    {
      query: {
        enabled:
          !!tipsParams && (filtersModified.current || !!initialFiltersData),
      },
    },
  );

  const { data: tipsData, isLoading: tipsLoading } = useGetTipsPerformance(
    {
      ...tipsParams,
      from: date.from,
      to: date.to,
      page: currentPage,
      type: activeTab,
    },
    {
      query: {
        enabled:
          !!tipsParams && (filtersModified.current || !!initialFiltersData),
      },
    },
  );

  useEffect(() => {
    const reconstructedFilters = reconstructFilters(initialFiltersData || {});

    if (!filtersModified.current) {
      setFilters(reconstructedFilters);

      handleSubmit(reconstructedFilters);
    }
  }, [initialFiltersData]);

  const handleFiltersChange = (newFilters: Filter[]) => {
    setFilters(newFilters);
    filtersModified.current = true;
  };

  const handleFiltersSubmit = () => {
    filtersModified.current = true;
    handleSubmit();
  };

  useEffect(() => {
    if (
      tipsData?.totalPages &&
      currentPage > tipsData.totalPages &&
      tipsData.totalPages !== 0
    ) {
      setCurrentPage(tipsData.totalPages);
    }
  }, [tipsData?.totalPages, currentPage]);

  useEffect(() => {
    if (!tipsLoading && tipsData?.totalPages != null) {
      setTotalPages(tipsData?.totalPages);
    }
  }, [tipsData?.totalPages, tipsLoading]);

  const handleSubmit = async (givenFilters = filters) => {
    const finalFilters = givenFilters.filter((f) => f.enabled);
    setCurrentPage(1);

    const params: Partial<GetTipsParams> = {};

    finalFilters.forEach((filter) => {
      if (
        filter.type === "multi-select" ||
        filter.type === "multi-select-image" ||
        filter.type === "multi-select-colored"
      ) {
        const key = `include_${filter.id}` as keyof GetTipsMatchesQueryParams;
        params[key] = filter.value as never;
      }

      if (filter.type === "range") {
        const min_key = `min_${filter.id}` as keyof GetTipsMatchesQueryParams;
        const max_key = `max_${filter.id}` as keyof GetTipsMatchesQueryParams;
        params[min_key] = filter.value.min as never;
        params[max_key] = filter.value.max as never;
      }

      if (filter.type === "switch") {
        params[filter.id as keyof GetTipsMatchesQueryParams] =
          filter.value as never;
      }
    });

    setTipsParams(params);
  };

  const filtersLoading =
    summaryLoading || (!filtersModified.current && initialFiltersLoading);

  return (
    <Stack direction="column" gap="xl">
      <TitleBlock
        title="AI Performance"
        subtitle="Find the most profitable strategy using the filters"
      />
      <Columns isMultiline>
        <Column tablet={12} widescreen={5} fullhd={4}>
          <DatePickerBox
            onChange={(date) => {
              setCurrentPage(1);
              setDate(date);
            }}
            value={date}
            enabledTimePeriods={[
              "today",
              "yesterday",
              "last-7-days",
              "last-30-days",
              "custom",
            ]}
          />
        </Column>
        <Column tablet={12} widescreen={7} fullhd={8}>
          <Stack isFullwidth direction="column" gap="xl">
            <Tabs
              active={activeTab}
              onChange={(tab) => setActiveTab(tab as TipType)}
            >
              <TabList>
                <TabItem tab="resultOriented">
                  <Icon icon="Target" />
                  <span>Result Oriented</span>
                </TabItem>
                <TabItem tab="value">
                  <Icon icon="ChartBar" />
                  <span>Value Based</span>
                </TabItem>
                <a
                  className="info-link"
                  onClick={() => modal.open("tipTypeInfo")}
                >
                  What&#39;s this?
                </a>
              </TabList>
              <TopPerformers from={date.from} to={date.to} type={activeTab} />
              <MultiFilter
                filters={filters}
                onFiltersChange={handleFiltersChange}
                enableNotification
                onSubmit={handleFiltersSubmit}
                isLoading={filtersLoading}
              />
              <Stack direction="column" gap="md">
                <Stack direction="column" gap="xxl">
                  <AIPerformanceCards
                    data={summaryData}
                    isLoading={summaryLoading}
                    linkLoading={initialFiltersRefetching}
                    linkText={
                      Number(summaryData?.profit_loss) <= 0 &&
                      filtersModified.current
                        ? "Recommend Strategy"
                        : ""
                    }
                    onLinkClick={async () => {
                      const result = await refetchInitialFilters();

                      if (result.data) {
                        filtersModified.current = false;
                        const reconstructedFilters = reconstructFilters(
                          result.data || {},
                        );

                        if (!filtersModified.current) {
                          setFilters(reconstructedFilters);

                          handleSubmit(reconstructedFilters);
                        }
                      }
                    }}
                  />
                  <Stack gap="md" direction="column">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                      isLoading={tipsLoading}
                    />
                    {(tipsLoading ||
                      filtersLoading ||
                      initialFiltersLoading) && (
                      <>
                        <TipCardSkeleton />
                        <TipCardSkeleton />
                        <TipCardSkeleton />
                      </>
                    )}
                    {!tipsLoading &&
                      !filtersLoading &&
                      (!tipsData || tipsData.data.length === 0) && (
                        <InfoBox
                          icon="SmileyXEyes"
                          hasSpacing
                          title="No tips found ..."
                        >
                          We couldn&apos;t find any tips with your filter
                          criteria. <br />
                          Choose another date or try to modify your search.
                        </InfoBox>
                      )}
                    {tipsData?.data.map(({ fixture, tips }) => {
                      return (
                        <TipBox
                          key={`result-oriented-match-fixture-${fixture.fixture.id}`}
                          fixture={fixture}
                        >
                          <TipsTable
                            tips={tips}
                            fixture={fixture}
                            type={activeTab}
                          />
                        </TipBox>
                      );
                    })}
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                      isLoading={tipsLoading}
                      inBottom
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Tabs>
          </Stack>
        </Column>
      </Columns>
    </Stack>
  );
};
