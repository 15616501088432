import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import dayjs from "dayjs";
import party from "party-js";
import { useQueryClient } from "react-query";

import { useGetFixture } from "@apiv2/o1-typescript-service";

import { FinishedStates, LiveStates } from "@api/football/client";
import { FixtureItem } from "@api/football/types/fixture";
import { GET_UPCOMING_TIPS_QUERY_KEY } from "@api/math-tips";
import { GET_USER_TIPS_QUERY_KEY } from "@api/user/getTips";
import { GET_USER_TIPS_SUMMARY_QUERY_KEY } from "@api/user/getTipsSummary";

import {
  Icon,
  Icons,
  ImageComponent,
  StatusBadge,
  Tag,
  Text,
} from "@ui/elements";
import { Stack } from "@ui/layout";

import "./TeamsHeader.scss";

export const useTeamsHeaderProps = (
  fixture?: FixtureItem,
): TeamsHeaderProps => {
  const isLive =
    LiveStates.includes(fixture?.fixture.status.short || "") ||
    (dayjs(fixture?.fixture.date).isBefore(dayjs()) &&
      !FinishedStates.includes(fixture?.fixture.status.short || ""));
  const [shouldRefetch, setShouldRefetch] = useState(isLive);
  const queryClient = useQueryClient();

  const { data: liveTipsData, isLoading: liveDataLoading } = useGetFixture(
    String(fixture?.fixture.id) || "",
    {
      query: {
        enabled: shouldRefetch,
        refetchInterval: 10000, // 10-30s
      },
    },
  );

  useEffect(() => {
    if (
      liveTipsData &&
      !LiveStates.includes(liveTipsData.fixture.status.short)
    ) {
      setShouldRefetch(false);

      queryClient.invalidateQueries([GET_UPCOMING_TIPS_QUERY_KEY]);
      queryClient.invalidateQueries([GET_USER_TIPS_QUERY_KEY]);
      queryClient.invalidateQueries([GET_USER_TIPS_SUMMARY_QUERY_KEY]);
    }
  }, [liveTipsData, queryClient]);

  return useMemo(() => {
    if (isLive) {
      setShouldRefetch(true);
    }

    const getScore = () => {
      if (FinishedStates.includes(fixture?.fixture.status.short || "")) {
        return `${fixture?.score.fulltime.home} - ${fixture?.score.fulltime.away}`;
      }

      if (isLive) {
        return `${liveTipsData?.goals.home} - ${liveTipsData?.goals.away}`;
      }

      return "";
    };

    return {
      id: fixture?.fixture.id,
      homeLogo: fixture?.teams.home.logo,
      awayLogo: fixture?.teams.away.logo,
      title: `${fixture?.teams.home.name} vs ${fixture?.teams.away.name}`,
      ...(isLive
        ? {
            isLive,
            ...(["1H", "2H"].includes(liveTipsData?.fixture.status.short || "")
              ? { time: `${liveTipsData?.fixture.status.elapsed}'` }
              : {}),
            ...(["HT", "FT"].includes(liveTipsData?.fixture.status.short || "")
              ? { status: liveTipsData?.fixture.status.short }
              : {}),
            liveDataLoading,
          }
        : {}),
      score: getScore(),
      subtitle: (
        <>
          <TeamsInfoItem
            icon="CalendarBlank"
            label={dayjs(fixture?.fixture.date).format("DD MMMM")}
          />
          <TeamsInfoItem
            icon="Alarm"
            label={dayjs(fixture?.fixture.date).format("HH:mm")}
          />
          <TeamsInfoItem icon="Trophy" label={fixture?.league.name} />
        </>
      ),
    };
  }, [fixture, liveTipsData, isLive, liveDataLoading]);
};

type TeamsHeaderProps = {
  id?: string | number;
  homeLogo?: string;
  awayLogo?: string;
  title: ReactElement | string;
  score?: string;
  isLive?: boolean;
  liveDataLoading?: boolean;
  time?: number | string;
  status?: string;
  subtitle?: ReactElement;
  isLoading?: boolean;
};

export const TeamsHeader: FC<TeamsHeaderProps> = ({
  title,
  homeLogo,
  awayLogo,
  score,
  subtitle,
  isLoading,
  isLive,
  liveDataLoading,
  time,
  status,
}) => {
  const tagRef = useRef(null);
  const prevProp = useRef(score); // Initialize with the current prop value

  useEffect(() => {
    if (prevProp.current !== score) {
      if (!prevProp.current?.includes("undefined")) {
        if (tagRef.current) {
          const prevScore = {
            home: Number(prevProp.current?.split(" - ")[0]),
            away: Number(prevProp.current?.split(" - ")[1]),
          };
          const homeScore = Number(score?.split(" - ")[0]);
          const awayScore = Number(score?.split(" - ")[1]);

          const homeIncreased = homeScore > prevScore.home;
          const awayIncreased = awayScore > prevScore.away;

          if (homeIncreased || awayIncreased) {
            party.confetti(tagRef.current, {
              count: 21,
              spread: 9,
              size: 0.5,
            });
          }
        }
      }
      prevProp.current = score; // Update the ref to the current value
    }
  }, [score]);

  return (
    <Stack align="center" className="teams-header" isFullwidth>
      <div className="teams-header__image-title">
        <div className="teams-header__images">
          <ImageComponent
            isLoading={isLoading}
            isRounded
            padded
            size={48}
            src={homeLogo}
            className="image"
          />
          <ImageComponent
            isLoading={isLoading}
            isRounded
            padded
            size={48}
            src={awayLogo}
            className="image"
          />
        </div>

        <div className="min-w-0 flex-1 overflow-hidden">
          {typeof title === "string" ? (
            <Text isLoading={isLoading} className="teams-header__title">
              {title}
            </Text>
          ) : (
            <div className="teams-header__title">{title}</div>
          )}
          {subtitle && (
            <Stack className="teams-header__subtitle" gap="xs" align="center">
              {subtitle}
            </Stack>
          )}
        </div>
      </div>

      {(score || isLive) && (
        <div className="teams-header__score-container">
          {isLive && (
            <Stack align="center">
              {!liveDataLoading && (
                <Text className="has-text-danger" isNarrow>
                  {status || time}
                </Text>
              )}
              <div ref={tagRef}>
                <Tag
                  size="medium"
                  isLoading={isLoading || liveDataLoading}
                  type={isLive ? "danger" : undefined}
                  variant="light"
                >
                  {!liveDataLoading && (
                    <StatusBadge
                      className="live-status-badge"
                      size="small"
                      isPulse
                      type="danger"
                    />
                  )}
                  {!liveDataLoading ? score : "0 - 0"}
                </Tag>
              </div>
            </Stack>
          )}

          {!isLive && score && (
            <Tag size="medium" isLoading={isLoading} variant="light">
              {score}
            </Tag>
          )}
        </div>
      )}
    </Stack>
  );
};

type TeamsInfoItemProps = {
  icon?: Icons;
  label?: string;
  isLoading?: boolean;
};

export const TeamsInfoItem: FC<TeamsInfoItemProps> = ({
  icon,
  label,
  isLoading,
}) => {
  return (
    <Text
      hasIcon={!!icon}
      size="small"
      variant="secondary"
      className="teams-info-item"
      isLoading={isLoading}
    >
      {icon && <Icon icon={icon} size="small" />}
      <span>{label}</span>
    </Text>
  );
};
