import React, { FC } from "react";

import dayjs from "dayjs";

import { useBalance } from "@apiv2/o1-typescript-service";

import { Modal } from "@ui/components";
import { Button, Icon, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

type FailedUnlockModalProps = {
  onClose: (openSecondModal?: boolean) => void;
  open: boolean;
};

export const FailedUnlockModal: FC<FailedUnlockModalProps> = ({
  onClose,
  open,
}) => {
  const { data: balanceData } = useBalance();

  return (
    <Modal
      isActive={open}
      onClose={() => onClose(false)}
      header={
        <Stack align="center">
          <Icon icon="LockSimple" />
          <Title size={4}>Oh no!</Title>
        </Stack>
      }
      style={{ zIndex: 100 }}
      footer={
        <Stack justify="end" isFullwidth>
          <Button variant="light" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => onClose(true)}>
            View Plans
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" gap="xl">
        <Stack direction="column" gap="xl">
          <Title size={6}>
            You&#39;ve reached the maximum 3 free unlocks for today.
          </Title>
          <Text>
            You can unlock this match by subscribing to one of our plans, or
            wait until{" "}
            <strong>
              {dayjs(balanceData?.next_unlock_date).format(
                "MMM DD (dddd) HH:mm",
              )}
            </strong>
            .
          </Text>
        </Stack>
      </Stack>
    </Modal>
  );
};
