import React, { PropsWithChildren, useMemo, useState } from "react";
import { FC } from "react";

import classNames from "classnames";

import { Button, Icon, IconCard, Icons, ProgressBar, Text } from "@ui/elements";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import "./IconProgressBar.scss";

type IconProgressBarRule = {
  value: number;
  type: Colors | "text";
};

type IconProgressBarProps =
  | PropsWithChildren<{
      label: string;
      output?: string | number;
      boldOutput?: boolean;
      outputPostfix?: string;
      icon?: Icons;
      isLoading?: boolean;
      /** From 0 to 100 */
      value: number;
      type?: Colors | "text";
      rules?: IconProgressBarRule[];
    }>
  | PropsWithChildren<{
      label: string;
      output?: string | number;
      boldOutput?: boolean;
      outputPostfix?: string;
      icon?: Icons;
      isLoading?: boolean;
      /** From 0 to 100 */
      value: number[];
      type?: Colors | "text";
      rules?: IconProgressBarRule[];
    }>;

export const IconProgressBar: FC<IconProgressBarProps> = ({
  label,
  outputPostfix,
  output = "",
  value,
  icon,
  type,
  rules,
  children,
  boldOutput = true,
  isLoading,
}) => {
  const [isActive, setIsActive] = useState(false);
  const isMulti = Array.isArray(value);

  const progressType: Array<Colors | "text"> | Colors | "text" = useMemo(() => {
    if (rules && rules.length > 0) {
      const sortedRules = [...rules].sort((a, b) => a.value - b.value);

      if (isMulti) {
        return value.map((val) => {
          for (let i = 0; i < sortedRules.length; i++) {
            if (val < sortedRules[i].value) {
              return i === 0 ? sortedRules[0].type : sortedRules[i - 1].type;
            }
          }
          return sortedRules[sortedRules.length - 1].type;
        });
      } else {
        for (let i = 0; i < sortedRules.length; i++) {
          if (value < sortedRules[i].value) {
            return i === 0 ? sortedRules[0].type : sortedRules[i - 1].type;
          }
        }
        return sortedRules[sortedRules.length - 1].type;
      }
    }

    if (type) {
      return type;
    }

    return isMulti ? ["text", "text"] : "text";
  }, [type, rules, value, isMulti]);

  return (
    <div className="icon-progress-bar">
      <Stack
        className={classNames("icon-progress-bar__header", {
          "has-children": !!children,
        })}
        align="center"
        onClick={() => setIsActive(!isActive)}
      >
        {icon && isLoading && <IconCard isLoading={isLoading} icon="Trophy" />}
        {icon && !isLoading && (
          <IconCard
            isLoading={isLoading}
            icon={icon}
            type={isMulti ? (type as Colors) : (progressType as Colors)}
          />
        )}
        <Stack direction="column" gap="xxs" isFullwidth>
          <Stack justify="between">
            <Text>{label}</Text>
            {isLoading ? (
              <Stack gap="none" align="end">
                <Text isLoading>Yes 1</Text>
              </Stack>
            ) : (
              <Stack gap="none" align="end">
                <Text weight={boldOutput ? "bold" : "normal"}>{output}</Text>
                {outputPostfix && (
                  <Text variant="secondary" size="small">
                    {outputPostfix}
                  </Text>
                )}
              </Stack>
            )}
          </Stack>
          <ProgressBar
            isLoading={isLoading}
            type={progressType as Colors}
            value={value as number}
          />
        </Stack>
        {children && (
          <Button variant="white" isRounded size="small">
            <Icon icon={isActive ? "CaretDown" : "CaretRight"} />
          </Button>
        )}
      </Stack>
      {children && (
        <div
          className={classNames("icon-progress-bar__content", {
            "is-active": isActive,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};
