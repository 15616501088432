import React, { FC, useState } from "react";

import { Modal } from "@ui/components";
import {
  Button,
  Icon,
  ImageComponent,
  Subtitle,
  Text,
  Title,
} from "@ui/elements";
import { Checkbox } from "@ui/forms";
import { Stack } from "@ui/layout";

import "./TelegramJoinModal.scss";

import { CommonModalProps } from "@components";

interface TelegramJoinModalProps extends Omit<CommonModalProps, "onClose"> {
  onJoinTelegram?: (dismissedPermanently: boolean) => void; // Callback when user clicks "Join Telegram"
  onClose: (dismissedPermanently: boolean) => void; // Callback for "Never show again"
}

export const TelegramJoinModal: FC<TelegramJoinModalProps> = ({
  isOpen,
  onClose,
  onJoinTelegram,
}) => {
  const [dismissedPermanently, setDismissedPermanently] = useState(false);

  const handleDismiss = () => {
    onClose(dismissedPermanently);
  };

  const handleJoin = () => {
    if (onJoinTelegram) {
      onJoinTelegram(dismissedPermanently);
    }
  };

  return (
    <Modal
      isActive={isOpen}
      onClose={handleDismiss}
      className="telegram-modal"
      size="large"
      header={
        <Stack direction="column" align="start" gap="xs">
          <Stack direction="row" align="center" gap="md">
            <Icon icon="TelegramLogo" size="medium" />
            <Title size={4}>Don&#39;t miss out!</Title>
          </Stack>
        </Stack>
      }
      style={{ zIndex: 100 }}
      footer={
        <Stack justify="between" align="center" isFullwidth>
          <Stack direction="row" align="center" gap="sm">
            <Button variant="light" onClick={handleDismiss}>
              Dismiss
            </Button>
            <Checkbox
              checked={dismissedPermanently}
              onChange={(e) => setDismissedPermanently(e.target.checked)}
            >
              Don&#39;t show again
            </Checkbox>
          </Stack>
          <Button
            as="link"
            href="https://t.me/+vMSyhj7q38xkZDRk"
            target="_blank"
            className="telegram-join-button"
            onClick={handleJoin}
          >
            <Icon icon="TelegramLogo" />
            <span>Join Telegram</span>
          </Button>
        </Stack>
      }
    >
      <Stack direction="row" gap="md" className="telegram-modal-content">
        <div className="telegram-image-wrapper">
          <ImageComponent src="telegram-screenshot-mobile.png" />
        </div>
        <Stack direction="column" gap="xl" className="telegram-join-content">
          <Stack direction="column" gap="sm">
            <Stack direction="column" gap="xxs">
              <Title size={4}>Join private Telegram Channel</Title>
              <Subtitle size={5}>
                Get exclusive strategies, community tips!
              </Subtitle>
            </Stack>
            <div className="content">
              <blockquote>
                Users who join our private Telegram channel have&nbsp;
                <strong>significantly higher ROI</strong> in their portfolio.
                Get access to real-time predictions, community discussions, and
                early access to new features!
              </blockquote>
            </div>
          </Stack>
          <Stack direction="column" gap="sm">
            <Title size={5}>Why Join?</Title>
            <ul className="benefits-list">
              <li className="is-flex is-gap-0.5">
                <Icon
                  icon="CheckCircle"
                  className="has-text-success"
                  weight="fill"
                />{" "}
                Real-time admin predictions
              </li>
              <li className="is-flex is-gap-0.5">
                <Icon
                  icon="CheckCircle"
                  className="has-text-success"
                  weight="fill"
                />{" "}
                Community tips & tricks
              </li>
              <li className="is-flex is-gap-0.5">
                <Icon
                  icon="CheckCircle"
                  className="has-text-success"
                  weight="fill"
                />{" "}
                Timely updates & notifications
              </li>
              <li className="is-flex is-gap-0.5">
                <Icon
                  icon="CheckCircle"
                  className="has-text-success"
                  weight="fill"
                />{" "}
                Exclusive strategies
              </li>
              <li className="is-flex is-gap-0.5">
                <Icon
                  icon="CheckCircle"
                  className="has-text-success"
                  weight="fill"
                />{" "}
                Prediction games & giveaways
              </li>
            </ul>
          </Stack>
          <Text>Click the button below to join and get started!</Text>
        </Stack>
      </Stack>
    </Modal>
  );
};
