import React, { FC, useMemo } from "react";

import ReactMarkdown from "react-markdown";

import { useGetMatchAnalysis } from "@apiv2/o1-typescript-service";

import { IconProgressBar, InfoBox, useTabs } from "@ui/components";
import { Divider, IconCard, StatusBadge, Tag, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import { useMatchDetails } from "@components";

type CorrectScoreSectionProps = {
  predictions?: string;
  final_score?: string;
};

const CorrectScoreSection: FC<CorrectScoreSectionProps> = ({
  predictions,
  final_score,
}) => {
  const [primaryPrediction, secondaryPrediction] = useMemo(() => {
    if (predictions?.includes("or")) {
      return predictions?.split(" or ");
    }

    return [predictions, null];
  }, [predictions]);

  if (primaryPrediction && !secondaryPrediction) {
    return (
      <Stack justify="start">
        <Tag
          size="medium"
          type={primaryPrediction === final_score ? "success" : undefined}
        >
          {primaryPrediction}
        </Tag>
      </Stack>
    );
  }

  if (primaryPrediction && secondaryPrediction) {
    return (
      <Stack align="center">
        <Tag
          size="medium"
          type={primaryPrediction === final_score ? "success" : undefined}
        >
          {primaryPrediction}
        </Tag>
        <Text variant="secondary">/</Text>
        <Tag
          size="medium"
          type={secondaryPrediction === final_score ? "success" : undefined}
        >
          {secondaryPrediction}
        </Tag>
      </Stack>
    );
  }

  return null;
};

export const MatchAnalysis = () => {
  const { activeTab } = useTabs();
  const { fixture, isOpen } = useMatchDetails();
  const { data: matchAnalysis, isLoading: matchAnalysisLoading } =
    useGetMatchAnalysis(String(fixture.fixture.id), {
      query: {
        enabled: activeTab === "match-analysis" && isOpen,
      },
    });

  const defaultProgressRules = useMemo(
    () => [
      {
        value: 0,
        type: "danger" as Colors,
      },
      {
        value: 60,
        type: "warning" as Colors,
      },
      {
        value: 80,
        type: "success" as Colors,
      },
    ],
    [],
  );

  const reverseProgressRules = useMemo(
    () => [
      {
        value: 0,
        type: "success" as Colors,
      },
      {
        value: 21,
        type: "warning" as Colors,
      },
      {
        value: 61,
        type: "danger" as Colors,
      },
    ],
    [],
  );

  const fixtureScore = `${fixture.score.fulltime.home}-${fixture.score.fulltime.away}`;

  if (!matchAnalysis?.id && !matchAnalysisLoading) {
    return (
      <InfoBox title="Ooops ..." hasSpacing>
        Seems like this match doesn&apos;t have AI analysis ready.
        <br />
        Please try again later.
      </InfoBox>
    );
  }

  return (
    <>
      <Stack gap="xs" direction="column">
        <Title size={5}>Match Preview</Title>
        {matchAnalysisLoading && (
          <div className="skeleton-lines">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        <Text weight="light">
          <ReactMarkdown>{matchAnalysis?.match_preview}</ReactMarkdown>
        </Text>
        <Stack direction="column" gap="lg">
          <IconProgressBar
            isLoading={matchAnalysisLoading}
            label="Predictability"
            output={matchAnalysis?.summary.predictability}
            outputPostfix="/10"
            icon="Clover"
            rules={defaultProgressRules}
            value={Number(matchAnalysis?.summary.predictability) * 10}
          />
          <IconProgressBar
            isLoading={matchAnalysisLoading}
            label="Safe for Betting"
            output={matchAnalysis?.summary.safe_for_betting ? "Yes" : "No"}
            icon="ShieldCheck"
            type={
              matchAnalysis?.summary.safe_for_betting ? "success" : "danger"
            }
            value={100}
          />
          <IconProgressBar
            isLoading={matchAnalysisLoading}
            label="Match Importance"
            output={matchAnalysis?.summary.match_importance}
            outputPostfix="/10"
            icon="Trophy"
            rules={reverseProgressRules}
            value={Number(matchAnalysis?.summary.match_importance) * 10}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack gap="sm" direction="column">
        <Stack direction="column" gap="xxs">
          <Title size={5}>Correct Score Prediction</Title>
          <CorrectScoreSection
            predictions={matchAnalysis?.correct_score}
            final_score={fixtureScore}
          />
        </Stack>
        {matchAnalysisLoading && (
          <div className="skeleton-lines">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}

        {matchAnalysis?.correct_score &&
          matchAnalysis?.correct_score_reasoning && (
            <Stack direction="column" gap="lg">
              <Text weight="light">
                <ReactMarkdown>
                  {matchAnalysis.correct_score_reasoning}
                </ReactMarkdown>
              </Text>
            </Stack>
          )}
      </Stack>
      <Divider />
      <Stack gap="xs" direction="column">
        <Title size={5}>Team Comparision</Title>
        {matchAnalysisLoading && (
          <div className="skeleton-lines">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        <Text weight="light">
          <ReactMarkdown>
            {matchAnalysis?.team_comparison.reasoning}
          </ReactMarkdown>
        </Text>
        <IconProgressBar
          label="Team Health Index"
          output={`${matchAnalysis?.team_comparison.home_team.team_health_index}-${matchAnalysis?.team_comparison.away_team.team_health_index}`}
          icon="ShieldStar"
          type="info"
          isLoading={matchAnalysisLoading}
          rules={defaultProgressRules}
          value={[
            Number(matchAnalysis?.team_comparison.home_team.team_health_index) *
              10,
            Number(matchAnalysis?.team_comparison.away_team.team_health_index) *
              10,
          ]}
        >
          <Stack direction="column" gap="md">
            <IconProgressBar
              label="Motivations"
              boldOutput={false}
              isLoading={matchAnalysisLoading}
              output={`${matchAnalysis?.team_comparison.home_team.motivations}-${matchAnalysis?.team_comparison.away_team.motivations}`}
              value={[
                Number(matchAnalysis?.team_comparison.home_team.motivations) *
                  10,
                Number(matchAnalysis?.team_comparison.away_team.motivations) *
                  10,
              ]}
              rules={defaultProgressRules}
            />
            <IconProgressBar
              label="Injury Impact"
              isLoading={matchAnalysisLoading}
              boldOutput={false}
              output={`${matchAnalysis?.team_comparison.home_team.injury_impact}-${matchAnalysis?.team_comparison.away_team.injury_impact}`}
              value={[
                Number(matchAnalysis?.team_comparison.home_team.injury_impact) *
                  10,
                Number(matchAnalysis?.team_comparison.away_team.injury_impact) *
                  10,
              ]}
              rules={reverseProgressRules}
            />
            <IconProgressBar
              label="Tactical Advantage"
              isLoading={matchAnalysisLoading}
              boldOutput={false}
              output={`${matchAnalysis?.team_comparison.home_team.tactical_advantage}-${matchAnalysis?.team_comparison.away_team.tactical_advantage}`}
              value={[
                Number(
                  matchAnalysis?.team_comparison.home_team.tactical_advantage,
                ) * 10,
                Number(
                  matchAnalysis?.team_comparison.away_team.tactical_advantage,
                ) * 10,
              ]}
              rules={defaultProgressRules}
            />
            <IconProgressBar
              label="Recent Form"
              isLoading={matchAnalysisLoading}
              boldOutput={false}
              output={`${matchAnalysis?.team_comparison.home_team.recent_form}-${matchAnalysis?.team_comparison.away_team.recent_form}`}
              value={[
                Number(matchAnalysis?.team_comparison.home_team.recent_form) *
                  10,
                Number(matchAnalysis?.team_comparison.away_team.recent_form) *
                  10,
              ]}
              rules={defaultProgressRules}
            />
          </Stack>
        </IconProgressBar>
      </Stack>
      <Divider />
      <Stack gap={matchAnalysisLoading ? "lg" : "xs"} direction="column">
        {matchAnalysisLoading && (
          <div className="skeleton-lines">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        <Stack gap="xxl" direction="column">
          <Stack isFullwidth direction="column">
            <Stack align="center">
              <IconCard icon="ThumbsUp" type="success" />
              <Stack direction="column" gap="none">
                <Title size={5}>Tips</Title>
                <Text variant="secondary">Recommendations</Text>
              </Stack>
            </Stack>
            <Stack direction="column">
              {matchAnalysisLoading && (
                <div className="skeleton-lines">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
              {matchAnalysis?.recommendations.betting_recommendations.map(
                ({ title, short_reasoning }) => (
                  <Stack
                    direction="column"
                    gap="none"
                    key={`${matchAnalysis?.id}-recommendation-${title}`}
                  >
                    <Stack gap="xs">
                      <StatusBadge type="success" />
                      <Text weight="bold">{title}</Text>
                    </Stack>
                    <Text variant="secondary">{short_reasoning}</Text>
                  </Stack>
                ),
              )}
            </Stack>
          </Stack>
          <Stack isFullwidth direction="column">
            <Stack align="center">
              <IconCard icon="ThumbsDown" type="danger" />
              <Stack direction="column" gap="none">
                <Title size={5}>Risks</Title>
                <Text variant="secondary">Things to Avoid</Text>
              </Stack>
            </Stack>
            <Stack direction="column">
              {matchAnalysisLoading && (
                <div className="skeleton-lines">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
              {matchAnalysis?.recommendations.things_to_avoid.map(
                ({ title, short_reasoning }) => (
                  <Stack
                    direction="column"
                    gap="none"
                    key={`${matchAnalysis?.id}-avoid-${title}`}
                  >
                    <Stack gap="xs">
                      <StatusBadge type="danger" />
                      <Text weight="bold">{title}</Text>
                    </Stack>
                    <Text variant="secondary">{short_reasoning}</Text>
                  </Stack>
                ),
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
