import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

import {
  AccountSettingsModal,
  PreferencesModal,
  SubscriptionModal,
} from "@components";

import { ContactModal } from "../ContactModal/ContactModal";
import { TipTypeInfoModal } from "../TipTypeInfoModal/TipTypeInfoModal";
import { UserSourceModal } from "../UserSourceModal/UserSourceModal";

type ModalNames =
  | "subscription"
  | "accountSettings"
  | "preferences"
  | "tipTypeInfo"
  | "userSource"
  | "contact";

export type CommonModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type ModalContextType = {
  open: (name: ModalNames) => void;
  close: (name: ModalNames) => void;
  hasOpenedModal: boolean;
};
const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModal should be used within ModalContext provider");
  }

  return context;
};

const ModalComponents: Record<ModalNames, FC<CommonModalProps>> = {
  subscription: SubscriptionModal,
  accountSettings: AccountSettingsModal,
  preferences: PreferencesModal,
  contact: ContactModal,
  tipTypeInfo: TipTypeInfoModal,
  userSource: UserSourceModal,
};

type ModalState = Record<ModalNames, boolean>;

type ModalsProviderProps = PropsWithChildren;

export const ModalsProvider: FC<ModalsProviderProps> = ({ children }) => {
  const [modalStates, setModalStates] = useState<ModalState>({
    subscription: false,
    accountSettings: false,
    preferences: false,
    contact: false,
    tipTypeInfo: false,
    userSource: false,
  });

  const handleOpen = (name: ModalNames) => {
    setModalStates({
      ...modalStates,
      [name]: true,
    });
  };

  const handleClose = (name: ModalNames) => {
    setModalStates({
      ...modalStates,
      [name]: false,
    });
  };

  const hasOpenedModal = Object.values(modalStates).some((isOpen) => isOpen);

  return (
    <ModalContext.Provider
      value={{ open: handleOpen, close: handleClose, hasOpenedModal }}
    >
      {children}
      {Object.entries(ModalComponents).map(([name, Component]) => (
        <Component
          key={`modal-${name}`}
          isOpen={modalStates[name as ModalNames]}
          onClose={() => handleClose(name as ModalNames)}
        />
      ))}
    </ModalContext.Provider>
  );
};
