import React, { useState } from "react";

import { signInWithRedirect } from "aws-amplify/auth";

import { Button, ImageComponent } from "@ui/elements";
import { Stack } from "@ui/layout";

import AppleIcon from "./apple-icon.svg";
import FacebookIcon from "./facebook-icon.svg";
import GoogleIcon from "./google-icon.svg";

type SocialProviders = "Apple" | "Google" | "Facebook";

type LoadingState = Record<SocialProviders, boolean>;
export const SocialLogin = () => {
  const [loadingStates, setLoadingStates] = useState<LoadingState>({
    Apple: false,
    Google: false,
    Facebook: false,
  });

  const handleSocialLogin = async (
    provider: "Apple" | "Google" | "Facebook",
  ) => {
    setLoadingStates({
      ...loadingStates,
      [provider]: true,
    });

    await signInWithRedirect({
      provider: provider,
    });
  };

  return (
    <Stack direction="column">
      <Button
        type="button"
        variant="light"
        isLoading={loadingStates.Apple}
        onClick={() => {
          handleSocialLogin("Apple");
        }}
      >
        <Stack isFullwidth align="center" gap="sm">
          <ImageComponent size={16} src={AppleIcon} />
          <span>Continue with Apple</span>
        </Stack>
      </Button>
      <Button
        type="button"
        variant="light"
        isLoading={loadingStates.Google}
        onClick={() => {
          handleSocialLogin("Google");
        }}
      >
        <Stack isFullwidth align="center" gap="sm">
          <ImageComponent size={16} src={GoogleIcon} />
          <span>Continue with Google</span>
        </Stack>
      </Button>
      <Button
        type="button"
        variant="light"
        isLoading={loadingStates.Facebook}
        onClick={() => {
          handleSocialLogin("Facebook");
        }}
      >
        <Stack isFullwidth align="center" gap="sm">
          <ImageComponent size={16} src={FacebookIcon} />
          <span>Continue with Facebook</span>
        </Stack>
      </Button>
    </Stack>
  );
};
