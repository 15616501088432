import { Category } from "@apiv2/o1-typescript-service";

const TipCategoryNameMap: Partial<Record<Category, string>> = {
  "Total Cards Over/Under": "Total Cards",
  "Home Cards Over/Under": "Home Cards",
  "Away Cards Over/Under": "Away Cards",

  "Total Goals Over/Under": "Total Goals",
  "Home Goals Over/Under": "Home Goals",
  "Away Goals Over/Under": "Away Goals",

  "Total Corners Over/Under": "Total Corners",
  "Home Corners Over/Under": "Home Corners",
  "Away Corners Over/Under": "Away Corners",
};

export const formatTipCategory = (category: Category) => {
  return TipCategoryNameMap[category] || category;
};
