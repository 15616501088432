import React, { FC, useState } from "react";

import { useIsMutating, useQueryClient } from "@tanstack/react-query";

import {
  FixtureItem,
  getBalanceQueryKey,
  getGetUpcomingTipsQueryKey,
  getUnlockMutationOptions,
  TipType,
  useUnlock,
} from "@apiv2/o1-typescript-service";

import { Button, Icon } from "@ui/elements";
import { Stack } from "@ui/layout";

import { TipsTable, toaster, useModal } from "@components";

import { FailedUnlockModal } from "../FailedUnlockModal/FailedUnlockModal";

import styles from "./UnlockAnalysis.module.scss";

type UnlockAnalysisProps = {
  id: string;
  type: TipType;
};

export const UnlockAnalysis: FC<UnlockAnalysisProps> = ({ id, type }) => {
  const [unlockLoading, setUnlockLoading] = useState(false);
  const [openFailedUnlockModal, setOpenFailedUnlockModal] = useState(false);
  const modal = useModal();
  const { mutateAsync: unlockMatch, isPending: unlockMatchLoading } =
    useUnlock();
  const queryClient = useQueryClient();
  const isUnlocking = useIsMutating(getUnlockMutationOptions());

  const handleMatchUnlock = async () => {
    setUnlockLoading(true);
    const response = await unlockMatch({ id });

    if (!response.success) {
      if (response.reason === "max_analysis") {
        setOpenFailedUnlockModal(true);
      } else {
        toaster.error({
          title: "Failed to Unlock Match Analysis",
          message: response.message,
        });
      }
    }

    if (response.success) {
      await queryClient.invalidateQueries({
        queryKey: getGetUpcomingTipsQueryKey({
          type,
        }),
      });

      await queryClient.invalidateQueries({
        queryKey: getBalanceQueryKey(),
      });

      toaster.success({
        title: "Match Analysis Unlocked 🧞‍♂️",
        message: "You can now view the match analysis.",
      });
    }

    setUnlockLoading(false);
  };

  return (
    <Stack className={styles.container} direction="column">
      <TipsTable
        allowSaveEdit
        tips={[
          {
            name: "Placeholder",
            category: "Double Chance",
            best_choice: false,
            odds: 1.5,
            bet_amount: 5,
            reasoning: "Reasoning",
            edge: 10,
            fixture_id: "123123",
            id: "1223112",
            league_id: 2,
            probability: 85,
            rating: "safe",
            season: 2024,
            start_date: "2024-12-24T10:00:00Z",
          },
          {
            name: "Placeholder 2",
            category: "Team to Score First",
            best_choice: false,
            odds: 1.5,
            bet_amount: 5,
            reasoning: "Reasoning",
            edge: 10,
            fixture_id: "123123",
            id: "1223112",
            league_id: 2,
            probability: 85,
            rating: "value",
            season: 2024,
            start_date: "2024-10-10T10:00:00Z",
          },
          {
            name: "Over xyz goals",
            category: "Away Goals Over/Under",
            best_choice: false,
            odds: 1.5,
            bet_amount: 5,
            reasoning: "Reasoning",
            edge: 10,
            fixture_id: "123123",
            id: "1223112",
            league_id: 2,
            probability: 85,
            rating: "risky",
            season: 2024,
            start_date: "2024-10-10T10:00:00Z",
          },
        ]}
        fixture={
          {
            fixture: {
              status: {
                short: "NS",
              },
            },
            teams: {
              home: {
                name: "Home Team",
                logo: "/logo.png",
              },
              away: {
                name: "Home Team",
                logo: "/logo.png",
              },
            },
            league: {
              name: "League Name",
            },
          } as FixtureItem
        }
        type={type}
      />

      <FailedUnlockModal
        open={openFailedUnlockModal}
        onClose={(openSecondModal) => {
          setOpenFailedUnlockModal(false);
          if (openSecondModal) {
            modal.open("subscription");
          }
        }}
      />

      <div className={styles.overlay}>
        <Button
          onClick={handleMatchUnlock}
          isLoading={unlockMatchLoading || unlockLoading}
          disabled={!!isUnlocking || unlockLoading}
        >
          <Icon icon="LockSimpleOpen" />
          <span>Unlock AI Analysis</span>
        </Button>
      </div>
    </Stack>
  );
};
