import "./LoginLayout.scss";
import React, { FC, PropsWithChildren } from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Outlet } from "react-router-dom";

import { Box, Button, Icon, ImageComponent, Link, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || "NO_CAPTCHA_KEY";

export const LoginLayout: FC<PropsWithChildren> = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <div className="login-layout">
        <div className="login-layout__logo">
          <ImageComponent size={128} src="/logo.png" />
        </div>
        <div className="login-layout__content">
          <Box className="login-layout__box">
            <Outlet />
          </Box>
        </div>

        <Stack
          align="center"
          direction="column"
          className="login-layout__box-help"
        >
          <Text size="small">Do you need help?</Text>
          <Stack justify="center">
            <Button
              variant="light"
              isRounded
              as="link"
              href="https://wa.me/34645274071"
              target="_blank"
            >
              <Icon icon="WhatsappLogo" />
            </Button>
            <Button
              variant="light"
              isRounded
              as="link"
              href="https://t.me/slimtiexxx"
              target="_blank"
            >
              <Icon icon="TelegramLogo" />
            </Button>
            <Button
              variant="light"
              isRounded
              as="link"
              href="https://instagram.com/direct/t/geniebets"
              target="_blank"
            >
              <Icon icon="InstagramLogo" />
            </Button>
            <Button
              variant="light"
              isRounded
              as="link"
              href="mailto:info@football-genie.com"
              target="_blank"
            >
              <Icon icon="At" />
            </Button>
          </Stack>
        </Stack>
        <Stack justify="center" className="login-layout__legals">
          <Link target="_blank" href="/terms-and-conditions" size="small">
            Terms and Conditions
          </Link>
          <Link target="_blank" href="/privacy-policy" size="small">
            Privacy Policy
          </Link>
          <Link target="_blank" href="/disclaimer" size="small">
            Disclaimer
          </Link>
          <Link target="_blank" href="/cookies" size="small">
            Cookies
          </Link>
        </Stack>
      </div>
    </GoogleReCaptchaProvider>
  );
};
