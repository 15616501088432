import { FC, PropsWithChildren, useEffect, useState } from "react";

import { useGetUserSource } from "@apiv2/o1-typescript-service";

import { useModal } from "@components";

export const UserSourceProvider: FC<PropsWithChildren> = ({ children }) => {
  const modals = useModal();
  const [mounted, setMounted] = useState(false);
  const { data: userSourceData } = useGetUserSource();

  useEffect(() => {
    if (userSourceData) {
      if (!mounted) {
        if (userSourceData.source === "unknown") {
          modals.open("userSource");
        }
      }

      setMounted(true);
    }
  }, [userSourceData, mounted, modals]);

  return <>{children}</>;
};
