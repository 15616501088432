import React, { FC, HTMLAttributes, PropsWithChildren } from "react";

import classNames from "classnames";

import "./Text.scss";
import { Colors } from "../../../types";

type TextProps = PropsWithChildren<{
  size?: "normal" | "small";
  weight?: "normal" | "bold" | "light";
  variant?: "primary" | "secondary";
  color?: Colors;
  hasIcon?: boolean;
  isCenter?: boolean;
  isNarrow?: boolean;
  isLoading?: boolean;
  isClickable?: boolean;
  align?: "left" | "centered" | "right";
  noWrap?: boolean;
  truncate?: boolean;
}> &
  HTMLAttributes<HTMLParagraphElement>;

export const Text: FC<TextProps> = ({
  children,
  size,
  variant,
  weight,
  color,
  hasIcon,
  isNarrow,
  isCenter,
  isLoading,
  noWrap,
  isClickable,
  align,
  truncate,
  ...rest
}) => {
  return (
    <p
      {...rest}
      className={classNames(
        "text",
        {
          "is-size-6": size === "normal",
          "is-size-7": size === "small",
          secondary: variant === "secondary",
          "has-text-weight-bold": weight === "bold",
          "has-text-weight-normal": weight === "normal",
          "has-text-weight-light": weight === "light",
          "icon-text": hasIcon,
          "has-text-centered": isCenter,
          "is-narrow": isNarrow,
          "is-skeleton": isLoading,
          "is-clickable": isClickable,
          "is-no-wrap": noWrap,
          truncate: truncate,
          [`has-text-${color}`]: !!color,
          [`has-text-${align}`]: !!align,
        },
        rest.className,
      )}
    >
      {children}
    </p>
  );
};
