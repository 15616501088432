import React from "react";

import { Column, Columns, Stack } from "@ui/layout";

import { usePageSettings } from "../../../components/PageTemplate/PageTemplate";

import { AIPerformancePanel } from "./Panels/AIPerformancePanel/AIPerformancePanel";
import { MyBetsPanel } from "./Panels/MyBetsPanel/MyBetsPanel";
import { TopPerformersPanel } from "./Panels/TopPerformersPanel/TopPerformersPanel";
import { UpcomingTipsPanel } from "./Panels/UpcomingTipsPanel/UpcomingTipsPanel";

import "./Dashboard.scss";

export const Dashboard = () => {
  const pageSettings = usePageSettings();

  return (
    <Columns isMultiline className="dashboard-columns">
      {pageSettings.hasSubscription && (
        <Column size={12}>
          <MyBetsPanel />
        </Column>
      )}
      <Column tablet={7} desktop={12} widescreen={7}>
        <UpcomingTipsPanel />
      </Column>
      <Column tablet={5} desktop={12} widescreen={5}>
        <Stack direction="column" gap="xxl" align="start" isFullwidth>
          <AIPerformancePanel />
          <TopPerformersPanel />
        </Stack>
      </Column>
    </Columns>
  );
};
