import React, { FC } from "react";

import classNames from "classnames";
import { useForm } from "react-hook-form";

import { useCreateUserSource } from "@apiv2/o1-typescript-service";

import { Modal } from "@ui/components";
import { Button, Icon, Text, Title } from "@ui/elements";
import { Input, Radio } from "@ui/forms";
import { Stack } from "@ui/layout";

import { CommonModalProps, toaster } from "@components";

import { getUtmParams } from "../../utils/getUtmParams";

type UserSourceModalFormFields = {
  source: string;
  otherValue: string;
};

export const UserSourceModal: FC<CommonModalProps> = ({ isOpen, onClose }) => {
  const form = useForm<UserSourceModalFormFields>({
    defaultValues: {
      otherValue: "",
    },
  });
  const { mutateAsync: createUserSource, isPending: createSourceLoading } =
    useCreateUserSource();
  const source = form.watch("source");

  const onSubmit = async (values: UserSourceModalFormFields) => {
    const utm_source = getUtmParams().utm_source;
    if (values.source === "other") {
      await createUserSource({
        data: { source: `other - ${values.otherValue}`, utm_source },
      });
    } else {
      await createUserSource({ data: { source: values.source, utm_source } });
    }

    onClose();

    toaster.success({
      title: "Thank you for your response!",
      message:
        "We appreciate you taking the time to let us know how you discovered us.",
    });
  };

  return (
    <Modal
      disableClose={true}
      header={
        <Stack>
          <Icon icon="Sparkle" weight="fill" />
          <Title size={4}>Welcome to the Genie Team!</Title>
        </Stack>
      }
      isActive={isOpen}
      onClose={onClose}
      footer={
        <Stack justify="end" isFullwidth>
          <Button
            type="submit"
            color="primary"
            onClick={form.handleSubmit(onSubmit)}
            isLoading={createSourceLoading}
          >
            Continue
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" gap="xl">
        <Stack direction="column">
          <Text weight="bold">We’re thrilled to have you here. 🤝</Text>
          <Text>
            To help us understand how we’re connecting with fans like you, could
            you let us know how you discovered Football Genie?
          </Text>
        </Stack>

        <Stack direction="column" gap="sm">
          <Radio
            {...form.register("source", {
              required: "Please select how you discovered us.",
            })}
            value="instagram"
          >
            Instagram
          </Radio>
          <Radio
            {...form.register("source", {
              required: "Please select how you discovered us.",
            })}
            value="facebook"
          >
            Facebook
          </Radio>
          <Radio
            {...form.register("source", {
              required: "Please select how you discovered us.",
            })}
            value="tiktok"
          >
            TikTok
          </Radio>
          <Radio
            {...form.register("source", {
              required: "Please select how you discovered us.",
            })}
            value="google"
          >
            Google
          </Radio>
          <Radio
            {...form.register("source", {
              required: "Please select how you discovered us.",
            })}
            value="referral"
          >
            Referral
          </Radio>
          <Radio
            {...form.register("source", {
              required: "Please select how you discovered us.",
            })}
            value="other"
          >
            Other
          </Radio>
          {form.formState.errors.source && (
            <p className="help is-danger">
              {form.formState.errors.source.message}
            </p>
          )}
          <div className={classNames({ "is-hidden": source !== "other" })}>
            <Input
              {...form.register("otherValue", {
                required:
                  source === "other" ? "Please specify your source." : false,
              })}
              error={form.formState.errors.otherValue?.message}
              placeholder="Please specify"
            />
          </div>
        </Stack>
      </Stack>
    </Modal>
  );
};
