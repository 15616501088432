import React from "react";

import { Text } from "@ui/elements";

import { Filter, MultiFilterOptionItem, SwitchFilterProps } from "@components";

import { MultiSelectFilterProps } from "./MultiSelectFilterInput/MultiselectFilterInput";
import { RangeFilterProps } from "./RangeFilterInput/RangeFilterInput";

export const formatMultiSelectValue = (
  filter: MultiSelectFilterProps,
  options: MultiFilterOptionItem[],
) => {
  const maxItemsToShow = 2;
  const totalItems = filter.value.length;
  const itemsToShow = filter.value.slice(0, maxItemsToShow);
  const itemsWithLabels = itemsToShow.map((value) => {
    const option = options.find(
      (option) => String(option.value) === String(value),
    );
    return option?.label || value;
  });
  const hiddenItemCount = totalItems - itemsToShow.length;

  return (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        maxWidth: "200px",
      }}
    >
      <Text
        weight="bold"
        style={{
          flexShrink: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {itemsWithLabels.join(", ")}
        {hiddenItemCount > 0 && ","}
      </Text>
      {hiddenItemCount > 0 && (
        <Text
          style={{
            flexShrink: 0,
            marginLeft: "4px",
          }}
          variant="secondary"
          size="small"
        >
          +{hiddenItemCount}
        </Text>
      )}
    </span>
  );
};

export const formatRangeValue = (filter: RangeFilterProps) => (
  <Text
    variant="secondary"
    truncate
    style={{
      maxWidth: "200px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }}
  >
    from&nbsp;
    <strong>
      {typeof filter.value.min === "number"
        ? `${filter.formatValue ? filter.formatValue(filter.value.min) : filter.value.min}`
        : "..."}
    </strong>
    &nbsp;to&nbsp;
    <strong>
      {typeof filter.value.max === "number"
        ? `${filter.formatValue ? filter.formatValue(filter.value.max) : filter.value.max}`
        : "..."}
    </strong>
  </Text>
);

export const formatSwitchValue = (filter: SwitchFilterProps) => (
  <span>
    <strong>{filter.value ? "Yes" : "No"}</strong>
  </span>
);

export const defaultFormatFilterValue = (
  filter: Filter,
  optionsMap: { [filterId: string]: MultiFilterOptionItem[] },
) => {
  const filterOption = optionsMap[filter.id] || [];

  switch (filter.type) {
    case "multi-select":
    case "multi-select-colored":
    case "multi-select-image":
      return formatMultiSelectValue(filter, filterOption);
    case "range":
      return formatRangeValue(filter);
    case "switch":
      return formatSwitchValue(filter);
    default:
      return null;
  }
};
