import React, { FC, useEffect, useState } from "react";

import { AxiosError } from "axios";
import { useForm } from "react-hook-form";

import { useGetUserPreferences } from "@apiv2/o1-typescript-service";

import { withController } from "@utils";

import { useGetCurrencies } from "@api/user/getCurrencies";
import { useUpdatePreferences } from "@api/user/updatePreferences";

import { Modal } from "@ui/components";
import { Button, Text, Title } from "@ui/elements";
import { Select } from "@ui/forms";
import { Stack } from "@ui/layout";

import { toaster } from "@components";

type ChangeCurrencyConfirmModalProps = {
  onClose: () => void;
  open: boolean;
  currency: string;
};

const ChangeCurrencyConfirmModal: FC<ChangeCurrencyConfirmModalProps> = ({
  onClose,
  open,
  currency,
}) => {
  const { mutateAsync, isLoading, error } = useUpdatePreferences({
    onSuccess: () => {
      onClose();
      toaster.success({
        title: "Successful update!",
        message: "We've updated your default currency.",
      });
    },
  });

  const errorMessage = error?.response?.data.message;
  return (
    <Modal
      isActive={open}
      onClose={onClose}
      header="Currency Mismatch"
      style={{ zIndex: 100 }}
    >
      <Stack direction="column" gap="xl">
        <Stack direction="column" gap="xl">
          <Title size={6}>
            We found tips in your portfolio with a different currency.
          </Title>
          <Stack direction="column">
            <Text>
              Switching to <strong>{currency}</strong> will reset your saved
              bets and set <strong>{currency}</strong> as your default currency.
            </Text>
            <Text>Are you sure you wan&apos;t to continue?</Text>
          </Stack>
        </Stack>
        {errorMessage && (
          <div className="notification is-danger is-light is-size-7">
            {errorMessage}
          </div>
        )}
        <Stack justify="end">
          <Button variant="light" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="light"
            color="danger"
            onClick={() => mutateAsync({ currency, force: true })}
            isLoading={isLoading}
          >
            Yes, i&apos;m sure
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

type CurrencyFormFields = {
  currency: string;
};

const ControlledSelect = withController<CurrencyFormFields>()(Select);

export const CurrencySettings = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currencyConfirmModalOpen, setCurrencyConfirmModalOpen] =
    useState(false);
  const { data: userPreferenceData } = useGetUserPreferences();
  const { data: currenciesData, isLoading: currenciesLoading } =
    useGetCurrencies();
  const { handleSubmit, control, setValue, watch } =
    useForm<CurrencyFormFields>({
      defaultValues: {
        currency: userPreferenceData?.currency?.code,
      },
    });

  useEffect(() => {
    if (userPreferenceData) {
      setValue("currency", userPreferenceData?.currency?.code);
    }
  }, [userPreferenceData]);
  const { mutateAsync } = useUpdatePreferences();
  const currency = watch("currency");

  const onSubmit = async (values: CurrencyFormFields) => {
    setLoading(true);
    setError("");

    try {
      await mutateAsync(values);

      toaster.success({
        title: "Successful update!",
        message: "We've updated your default currency.",
      });
    } catch (error) {
      setLoading(false);
      if (error instanceof AxiosError) {
        if (error.response?.data.error === "Currency Mismatch") {
          return setCurrencyConfirmModalOpen(true);
        }

        return setError(error.response?.data.message);
      }

      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ChangeCurrencyConfirmModal
        currency={currency}
        open={currencyConfirmModalOpen}
        onClose={() => setCurrencyConfirmModalOpen(false)}
      />
      <Stack direction="column" gap="xl">
        {error && (
          <div className="notification is-danger is-light">{error}</div>
        )}
        <Stack direction="column">
          <Text variant="secondary">
            Select the currency used by your bookmaker to ensure that your bet
            amounts are displayed accurately in your chosen currency.
          </Text>
        </Stack>

        <Stack direction="column" gap="xxs">
          <ControlledSelect
            isLoading={currenciesLoading}
            options={
              currenciesData?.map(({ symbol, name, code }) => ({
                label: `${name} (${symbol})`,
                value: code,
              })) || [
                {
                  label: `${userPreferenceData?.currency?.name} (${userPreferenceData?.currency?.symbol})`,
                  value: userPreferenceData?.currency?.code || "",
                },
              ]
            }
            name="currency"
            control={control}
          />
          <Text variant="secondary" size="small">
            Not found yours?{" "}
            <a
              target="_blank"
              href="mailto:info@football-genie.com"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </Text>
        </Stack>

        <Stack justify="end">
          <Button variant="light" isLoading={loading}>
            Update
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
