import React, { useEffect } from "react";

import { useForm } from "react-hook-form";

import { useGetUserPreferences } from "@apiv2/o1-typescript-service";

import { useUpdatePreferences } from "@api/user/updatePreferences";

import { Button, Text } from "@ui/elements";
import { Checkbox } from "@ui/forms";
import { Stack } from "@ui/layout";

import { toaster } from "@components";

type BetaFeaturesFormValues = {
  allowResultOrientedV2: boolean;
};

export const BetaFeatures = () => {
  const { data: userPreferenceData } = useGetUserPreferences();
  const { register, handleSubmit, setValue } = useForm<BetaFeaturesFormValues>({
    defaultValues: {
      allowResultOrientedV2: !!userPreferenceData?.allowResultOrientedV2,
    },
  });

  useEffect(() => {
    if (userPreferenceData) {
      setValue(
        "allowResultOrientedV2",
        userPreferenceData?.allowResultOrientedV2 || false,
      );
    }
  }, [userPreferenceData]);

  const { mutateAsync, isLoading } = useUpdatePreferences();

  const onSubmit = async (values: BetaFeaturesFormValues) => {
    await mutateAsync(values);

    toaster.success({
      title: "Successful update!",
      message: "We've updated your allowed beta features list.",
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap="xl">
        <Text variant="secondary">
          Enable unreleased features here. Please note that these features may
          be buggy or occasionally unavailable.
        </Text>
        <Checkbox isCentered={false} {...register("allowResultOrientedV2")}>
          <Text weight="bold">Result Oriented V2</Text>
          <Text variant="secondary">
            An enhanced version of result-oriented tips. Compared to the current
            version, it supports a wider range of markets and includes basic
            bankroll management (1% to 5%).
          </Text>
        </Checkbox>
        <Stack justify="end">
          <Button variant="light" isLoading={isLoading}>
            Update
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
